// import { useNavigate } from '@tanstack/react-location'
import clsx from 'clsx'

interface LogoProperties {
	className?: string
}
const Logo = ({ className }: LogoProperties) => {
	// const navigate = useNavigate()
	// const handleNavigate = (location: string) =>
	// 	location && navigate({ to: location })
	return (
		<h1
			className={
				className
					? clsx('cursor-pointer font-bold text-logo-lemon ', className)
					: clsx('cursor-pointer text-title-large font-bold text-logo-lemon')
			}
			onClick={() => {}}
		>
			Dias<span className="text-blue">pay</span>
		</h1>
	)
}

export default Logo
