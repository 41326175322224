import { AxiosError } from 'axios'
import { ApiError } from '../api/models/ApiError'
import {
	ReactElement,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Listing, Listings } from '../api/models/market/Listings'
import { useAuth } from './AuthProvider'
import { useMarketApi } from '../hooks/useApi'
import _ from 'lodash'

export type Filters = {
	isNegotiable: boolean
	isPartSale: boolean
	sourceCurrency?: string
	destinationCurrency?: string
}

type Currency = {
	code: string
	name: string
	symbol: string
	minor: string
}
export interface MarketContextType {
	isLoading: boolean
	listings: Listings
	currencies: Currency[]
	filters: Filters
	selectedListing?: Listing
	sortListType: string
	openDrawer: boolean
	setOpenDrawer: (val: boolean) => void
	setSortListType: (val: string) => void
	setFilters: (filters: Filters) => void
	setSelectedListing: (listing: Listing) => void
	loadListings: () => Promise<Listings | undefined>
	setListings: (listings: Listings) => void
	error?: AxiosError<ApiError> | null
}

export const MarketContext = createContext<MarketContextType>(
	{} as MarketContextType
)

const MarketProvider = ({
	children,
}: {
	children: ReactNode | ReactNode[]
}): ReactElement => {
	const api = useMarketApi()
	const { user } = useAuth()

	const [openDrawer, setOpenDrawer] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<AxiosError<ApiError>>()
	const [sortListType, setSortListType] = useState<string>('ASC')
	const [currencies, setCurrencies] = useState<Currency[]>([])
	const [filters, setFilters] = useState<Filters>({
		isNegotiable: false,
		isPartSale: false,
		sourceCurrency: undefined,
		destinationCurrency: undefined,
	})
	const [listings, setListings] = useState<Listings>([])
	const [selectedListing, setSelectedListing] = useState<Listing>({
		id: undefined,
		seller: undefined,
		amount: 0,
		rate: 0,
		fee: 0,
		source: {
			currency: 'GBP',
			amount: 0,
		},
		destination: {
			currency: 'NGN',
			amount: 0,
		},
		status: undefined,
		allow_negotiation: false,
		allow_partial_swap: false,
		created_date: '',
	} as Listing)

	useEffect(() => {
		loadCurrencies()
			.then((currencies) => {
				setCurrencies(currencies!)
			})
			.finally(() => setIsLoading(false))
	}, [])

	useEffect(() => {
		loadListings()
	}, [])

	const loadCurrencies = async (): Promise<Currency[] | undefined> => {
		setIsLoading(true)
		try {
			const response = await api.get('/currencies')
			setIsLoading(false)
			return response.data?.data
		} catch (error) {
			console.log(error)
		}
	}

	const loadListings = async (): Promise<Listings | undefined> => {
		try {
			setIsLoading(true)
			const response = await api.get('/listings')
			var listingsSortedByDateDesc = _.orderBy(
				response.data.data,
				[(item) => item.created_date],
				['desc']
			)
			setIsLoading(false)
			setListings(listingsSortedByDateDesc)
			return listingsSortedByDateDesc
		} catch (error) {
			console.log(error)
		}
	}

	let filteredListings = listings
	// let filteredListings = listings?.filter(
	// 	(listing) => listing.seller == user?.customerId
	// )

	if (filters.sourceCurrency && filters.destinationCurrency) {
		filteredListings = filteredListings.filter(
			(listing) =>
				listing.source.currency === filters.sourceCurrency &&
				listing.destination.currency === filters.destinationCurrency
		)
	}
	if (filters.isNegotiable) {
		filteredListings = filteredListings.filter(
			(listing) => listing.allow_negotiation == filters.isNegotiable
		)
	}

	if (filters.isPartSale) {
		filteredListings.filter(
			(listing) => listing.allow_partial_swap === filters.isPartSale
		)
	}

	if (sortListType === 'ASC') {
		filteredListings = filteredListings.sort((a, b) => a.rate - b.rate)
	}
	if (sortListType === 'DESC') {
		filteredListings = filteredListings.sort((a, b) => b.rate - a.rate)
	}

	const context = useMemo(() => {
		return {
			isLoading,
			currencies,
			listings: filteredListings,
			selectedListing,
			setSelectedListing,
			filters,
			sortListType,
			setSortListType,
			setFilters,
			openDrawer,
			setOpenDrawer,
			loadListings,
			setListings,
			error,
		}
	}, [
		isLoading,
		loadCurrencies,
		loadListings,
		setListings,
		filteredListings,
		selectedListing,
		filters,
		sortListType,
		currencies,
	])

	return (
		<MarketContext.Provider value={context}>{children}</MarketContext.Provider>
	)
}

const useMarket = (): MarketContextType =>
	useContext<MarketContextType>(MarketContext)
export { MarketProvider, useMarket }
