import clsx from 'clsx'
import { forwardRef } from 'react'

/** This is just a styled input with some mandatory props */
type TextInputFieldProps = React.HTMLProps<HTMLInputElement> & {
	/** Used to pass custom styles onto component */
	className?: string
	/** Dirty field state */
	disabled?: boolean
	/** Error state */
	error?: boolean
	/** ID of the input field. Used to match with label */
	id: string
	/** Name of the field.  */
	name: string
	/** RegEx pattern for limiting to numbers (useful for invoking keypad on mobile) */
	pattern?: string
	/** Placeholder to display when the field is empty */
	placeholder?: string
	/** type of the field */
	type: string
	/** Field value */
	value?: string

	label?: string
}

// eslint-disable-next-line react/display-name
const TextInputFieldWithLabel = forwardRef<
	HTMLInputElement,
	TextInputFieldProps
>(
	(
		{
			className,
			disabled,
			error,
			id,
			name,
			label,
			pattern,
			placeholder,
			type,
			value,
			...rest
		}: TextInputFieldProps,
		ref
	): JSX.Element => {
		return (
			<div
				className={clsx(
					'relative flex h-12 rounded-sm  bg-input-grey text-grey',
					{ 'h-14': disabled }
				)}
			>
				<label
					htmlFor={label}
					className={clsx(
						'text-xs text-gray-900 absolute -top-2 left-2 inline-block bg-white px-1 font-medium',
						{ 'text-error': error }
					)}
				>
					{label}
				</label>
				<input
					data-testid={`${id}-test`}
					aria-invalid={error}
					disabled={disabled}
					id={id}
					name={name}
					pattern={pattern}
					placeholder={placeholder}
					type={type}
					{...rest}
					ref={ref}
					value={value}
					autoComplete="off"
					className={clsx(
						'text-gray-900 placeholder:text-gray-600 sm:text-sm block w-full rounded-md border-0 px-4 py-2 shadow-sm ring-1 ring-inset ring-dark-grey/60 focus:ring-1 focus:ring-inset focus:ring-dark-grey/40 sm:leading-6',
						'flex-1 border-none px-2 text-grey focus:outline-none',
						{
							'border-mid-gray border bg-input-grey': !error,
							'border  border-error bg-input-grey text-error': error,
							'opacity-40': disabled,
							'ring-error focus:ring-error': error,
						},
						className
					)}
				/>
			</div>
		)
	}
)

export default TextInputFieldWithLabel
