import { AxiosError } from 'axios'
import { useQuery, UseQueryResult } from 'react-query'
import { ApiError } from '../../api/models/ApiError'
import { GetWalletsResponse } from '../../api/models/market/Wallets'
import { marketApi } from '../../api'

const useWalletsQuery = (): UseQueryResult<
	GetWalletsResponse,
	AxiosError<ApiError>
> => {
	const token = sessionStorage.getItem('accessToken')

	const getUserWallets = async () =>
		await marketApi.get('/wallets', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	return useQuery(['getUserWallets'], getUserWallets, {
		enabled: !!token,
	})
}

export default useWalletsQuery
