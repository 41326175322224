import FormLabel from '@mui/joy/FormLabel'
import Radio, { radioClasses } from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup'
import Sheet from '@mui/joy/Sheet'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Wallet } from '../../api/models/market/Wallets'
import { IconButton } from '@mui/joy'
import { formatCurrency } from '../../utils/utils'
import { CurrencyCode, currencySymbols } from '../../enums/wallet'

interface WalletSelectionProperties {
	selected: Wallet
	setSelected: (wallet: Wallet) => void
	accounts: Wallet[]
}

function WalletSelection({
	selected,
	setSelected,
	accounts,
}: WalletSelectionProperties) {
	return (
		<RadioGroup
			aria-label="platform"
			defaultValue="Website"
			overlay
			name="platform"
			sx={{
				flexDirection: 'row',
				gap: 2,
				[`& .${radioClasses.checked}`]: {
					[`& .${radioClasses.action}`]: {
						inset: -1,
						border: '3px solid',
						borderColor: '#425C81',
						outline: 'none',
					},
				},
				[`& .${radioClasses.radio}`]: {
					display: 'contents',
					'& > svg': {
						zIndex: 2,
						position: 'absolute',
						top: '-8px',
						right: '-8px',
						bgcolor: '#425C81',
						borderRadius: '50%',
						color: '#425C81',
						backgroundColor: 'white',
					},
				},
			}}
		>
			{accounts.map((account, index) => (
				<Sheet
					key={account.id}
					variant="outlined"
					sx={{
						borderRadius: 'md',
						bgcolor: 'background.body',
						boxShadow: 'sm',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 1.5,
						p: 2,
						minWidth: 120,
						width: 200,
						fontFamily: 'Poppins',
						outline: 'none',
					}}
				>
					<Radio
						id={account.currency.code}
						value={account.currency.name}
						checkedIcon={<CheckCircleRoundedIcon />}
						onChange={() => setSelected(account)}
						checked={!selected ? index === 0 : selected?.id === account.id}
					/>

					<IconButton
						size="lg"
						variant="plain"
						sx={{
							borderRadius: '50%',
							backgroundColor: '#F2F2F7',
							color: '#425C81',
							fontFamily: 'Poppins',
							fontWeight: 700,
						}}
					>
						{currencySymbols[account.currency.code as CurrencyCode]}
					</IconButton>
					<span className=" font-poppins text-body font-bold">
						{formatCurrency(account.balance)}
					</span>

					<FormLabel
						htmlFor={account.currency.name}
						sx={{ fontFamily: 'Poppins' }}
					>
						{account.currency.name}
					</FormLabel>
				</Sheet>
			))}
		</RadioGroup>
	)
}

export default WalletSelection
