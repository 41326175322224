import { Navigate, useRouter } from '@tanstack/react-location'
import type { ReactElement } from 'react'
import { useAuth } from '../../../contexts/AuthProvider'

interface ProtectedRouteProperties {
	type: 'any' | 'authenticated' | 'unauthenticated'
	children: ReactElement
}
const ProtectedRoute = ({ type, children }: ProtectedRouteProperties) => {
	const { initializingAuth, isAuthenticated } = useAuth()
	const {
		state: {
			location: { pathname },
		},
	} = useRouter()

	if (initializingAuth) return <div>Splash screen</div>

	if (type === 'authenticated' && !isAuthenticated)
		return (
			<Navigate to="/auth/sign-in" search={{ redirect: pathname }} replace />
		)

	if (type === 'unauthenticated' && isAuthenticated)
		return <Navigate to="/user/home" replace />

	return children
}

export default ProtectedRoute
