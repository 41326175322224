import { Space } from 'antd'
import { useAuth } from '../../contexts/AuthProvider'
import { useMarket } from '../../contexts/MarketProvider'
import Spinner from '../Feedback/Spinner/Spinner'
import ListingItem from './Listing'
import InfoIcon from '@mui/icons-material/Info'
import { Link } from '@tanstack/react-location'

function Sell() {
	const { isAuthenticated, user, initializingAuth } = useAuth()
	const { setOpenDrawer, listings, setSelectedListing, isLoading } = useMarket()

	const myListings = listings.filter(
		(listing) => listing.seller === user?.customerId
	)

	if (isLoading || initializingAuth)
		return (
			<div className="flex min-h-[100px] ">
				<Spinner className="text-blue" />
			</div>
		)

	if (!isAuthenticated) {
		return (
			<Space direction="vertical" style={{ width: '100%' }}>
				<Space direction="vertical" style={{ width: '100%', marginTop: 28 }}>
					<div className="flex h-12 items-center justify-start gap-2 rounded-lg border border-t-blue bg-blue bg-opacity-5 px-4 font-poppins text-[12px] text-blue">
						<InfoIcon />
						<span>
							Please{' '}
							<Link to={'/auth/sign-in'} className="mr-1 font-bold underline">
								login
							</Link>{' '}
							or
							<Link
								to={'/auth/create-account'}
								className="ml-1 font-bold underline"
							>
								create an account
							</Link>{' '}
							to perform any transaction.
						</span>
					</div>
				</Space>
			</Space>
		)
	}

	if (myListings.length === 0) {
		return (
			<Space direction="vertical" style={{ width: '100%' }}>
				<Space direction="vertical" style={{ width: '100%', marginTop: 28 }}>
					<div className="flex h-12 items-center justify-start gap-2 rounded-lg border border-t-blue bg-blue bg-opacity-5 px-4 font-poppins text-[12px] text-blue">
						<InfoIcon />
						<span>You don't have any available listing</span>
					</div>
				</Space>
			</Space>
		)
	}

	return (
		<>
			{myListings.map((listing) => (
				<ListingItem
					key={listing.id}
					listing={listing}
					setSelectedListing={setSelectedListing}
					setOpenDrawer={setOpenDrawer}
				/>
			))}
		</>
	)
}

export default Sell
