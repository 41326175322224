import { faker } from '@faker-js/faker'
import { listings } from '../data/listings'
import { customers } from '../data/customer'
import { mockWallets } from '../data/wallets'

export const mockGetListingsController = (req, res, ctx) => {
	return res(
		ctx.delay(250),
		ctx.status(200),
		ctx.json({
			status: '00',
			message: 'Ok',
			paginate: {
				total_records: listings.length,
				total_pages: 1,
				page_size: 20,
				page_number: 1,
			},
			data: listings,
		})
	)
}

export const mockAddListingController = (req, res, ctx) => {
	const header = req.headers.get('authorization')
	const [bearer, mockToken] = header.split(' ')
	const user = customers.find((customer) => customer.token === mockToken)
	const {
		amount,
		rate,
		source_currency,
		destination_currency,
		allow_negotiation,
		allow_partial_swap,
	} = req.body
	const newListing = {
		id: faker.string.uuid(),
		seller: user.customerId,
		amount,
		rate,
		fee: 5,
		source: {
			currency: source_currency,
			amount: amount,
		},
		destination: { currency: destination_currency, amount: rate * amount },
		status: 'open',
		allow_negotiation,
		allow_partial_swap,
		created_date: new Date().toISOString(),
	}
	if (mockToken && user) {
		return res(
			ctx.delay(400),
			ctx.status(200),
			ctx.json({
				status: '00',
				message: 'Ok',
				data: listings.push(newListing),
			})
		)
	}

	return res(
		ctx.delay(200),
		ctx.status(400),
		ctx.json({ message: 'An error has occurred, Please try again' })
	)
}

export const mockUpdateListingController = (req, res, ctx) => {
	const header = req.headers.get('authorization')
	const [bearer, mockToken] = header.split(' ')
	const user = customers.find((customer) => customer.token === mockToken)
	const listingId = req.params.listingId
	const {
		amount,
		rate,
		source,
		destination,
		allow_negotiation,
		allow_partial_swap,
	} = req.body

	if (mockToken && user) {
		const listingIndex = listings.findIndex(
			(listing) => listing.id === listingId
		)
		if (listingIndex === -1) {
			return res(
				ctx.delay(200),
				ctx.status(404),
				ctx.json({
					status: '00',
					message: 'Listing not found',
				})
			)
		}
		const listingToBeUpdated = listings[listingIndex]
		const newUpdatedListing = {
			...listingToBeUpdated,
			amount,
			rate,
			source: {
				...listingToBeUpdated.source,
				currency: source.currency,
				amount: amount,
			},
			destination: {
				...listingToBeUpdated.destination,
				currency: destination.currency,
				amount: rate * amount,
			},
			allow_negotiation,
			allow_partial_swap,
		}

		listings.splice(listingIndex, 1, newUpdatedListing)
		return res(
			ctx.delay(400),
			ctx.status(200),
			ctx.json({
				status: '00',
				message: 'Ok',
			})
		)
	}

	return res(
		ctx.delay(200),
		ctx.status(400),
		ctx.json({ message: 'An error has occurred, Please try again' })
	)
}

export const mockDeleteListingController = (req, res, ctx) => {
	const header = req.headers.get('authorization')
	const [bearer, mockToken] = header.split(' ')
	const user = customers.find((customer) => customer.token === mockToken)
	const listingId = req.params.listingId

	if (mockToken && user) {
		const listingIndex = listings.findIndex(
			(listing) => listing.id === listingId
		)
		if (listingIndex === -1) {
			return res(
				ctx.delay(200),
				ctx.status(404),
				ctx.json({
					status: '00',
					message: 'Listing not found',
				})
			)
		}
		listings.splice(listingIndex, 1)
		return res(
			ctx.delay(400),
			ctx.status(200),
			ctx.json({
				status: '00',
				message: 'Ok',
			})
		)
	}

	return res(
		ctx.delay(200),
		ctx.status(400),
		ctx.json({ message: 'An error has occurred, Please try again' })
	)
}

export const mockSwapListingController = (req, res, ctx) => {
	const header = req.headers.get('authorization')
	const [bearer, mockToken] = header.split(' ')
	const user = customers.find((customer) => customer.token === mockToken)

	const listingId = req.params.listingId
	const { amount } = req.body

	if (mockToken && user) {
		//get auth user waller
		let userWallets = mockWallets[user.customerId]
		const listingIndex = listings.findIndex(
			(listing) => listing.id === listingId
		)
		if (listingIndex === -1) {
			return res(
				ctx.delay(200),
				ctx.status(404),
				ctx.json({
					status: '00',
					message: 'Listing not found',
				})
			)
		}

		//get the listing in question
		const listingToBeSwapped = listings[listingIndex]
		//get the sellerId from the listing
		const sellerId = listingToBeSwapped.seller
		//get the seller's wallets
		let sellerWallets = mockWallets[sellerId]

		// check that the user has the value of the listing
		const { rate, fee, status, amount } = listingToBeSwapped
		const listingSourceCurrency = listingToBeSwapped.source.currency
		const listingDestinationCurrency = listingToBeSwapped.destination.currency

		// check that the listing is still open
		if (status === 'closed') {
			return res(
				ctx.delay(200),
				ctx.status(404),
				ctx.json({
					message: 'Listing is closed / no longer available',
				})
			)
		}

		//get the wallet of the user(buyer) that has the listing destination currency
		const destinationCurrencyWalletOfBuyer = userWallets.find(
			(wallet) => wallet.currency.code === listingDestinationCurrency
		)

		//check that the balance in the wallet is enough to buy the listing
		if (destinationCurrencyWalletOfBuyer.balance < rate * amount + fee) {
			return res(
				ctx.delay(200),
				ctx.status(400),
				ctx.json({
					message: 'You dont have the complete amount to make this transaction',
				})
			)
		}

		//check that seller still has the value of the currency he wishes to sell
		const sourceCurrencyWalletOfSeller = sellerWallets.find(
			(wallet) => wallet.currency.code === listingSourceCurrency
		)
		if (sourceCurrencyWalletOfSeller.balance < amount) {
			// TO DO or to be confirmed
			// At this point the listing should be closed as the seller no longer has the funds
			// Normally, we should not get to this condition if the listing validations work correctly/ funds were properly lien
			return res(
				ctx.delay(200),
				ctx.status(400),
				ctx.json({
					message: 'Seller does not have the value',
				})
			)
		}

		//debit seller's listing sourceWallet
		sellerWallets = sellerWallets.map((wallet) => {
			if (wallet.currency.code === listingSourceCurrency) {
				return {
					...wallet,
					balance: wallet.balance - amount,
				}
			}
			return wallet
		})

		// debit the buyer's listing destination wallet
		userWallets = userWallets.map((wallet) => {
			if (wallet.currency.code === listingDestinationCurrency) {
				return {
					...wallet,
					balance: wallet.balance - (amount * rate + fee),
				}
			}
			return wallet
		})

		// credit the seller's destination wallet
		sellerWallets = sellerWallets.map((wallet) => {
			if (wallet?.currency?.code === listingDestinationCurrency) {
				return {
					...wallet,
					balance: wallet.balance + amount * rate,
				}
			}
			return wallet
		})

		// credit buyer listing source wallet
		userWallets = userWallets.map((wallet) => {
			if (wallet?.currency?.code === listingSourceCurrency) {
				return {
					...wallet,
					balance: wallet.balance + amount,
				}
			}
			return wallet
		})

		// save and complete the transactions
		mockWallets[sellerId] = sellerWallets
		mockWallets[user.customerId] = userWallets

		return res(
			ctx.delay(200),
			ctx.status(201),
			ctx.json({
				status: '00',
				message: 'Completed',
			})
		)
	}
}
