import { ReactElement, useState } from 'react'

const useMultistepForm = (steps: ReactElement[], currentStepIndex: number) => {
	return {
		currentStepIndex,
		step: steps[currentStepIndex],
		steps,
	}
}

export default useMultistepForm
