export enum currencyCode {
	POUNDS = 'GBP',
	NAIRA = 'NGN',
}

export const currencySymbols = {
	NGN: '₦',
	GBP: '£',
	USD: '$',
}

export type CurrencyCode = 'NGN' | 'GBP' | 'USD'
