import { Drawer, Input, Space } from 'antd'
import { useMarket } from '../../contexts/MarketProvider'
import { X } from '@phosphor-icons/react'
import Button from '../../components/Inputs/Button/Button'
import { useAuth } from '../../contexts/AuthProvider'
import InfoIcon from '@mui/icons-material/Info'
import Dialog from '../../components/Feedback/Dialog/Dialog'
import WarningIcon from '@mui/icons-material/Warning'
import { useEffect, useState } from 'react'
import { marketApi } from '../../api'
import CustomSwitch from '../../components/Inputs/Switch/CustomSwitch'
import { Listing } from '../../api/models/market/Listings'
import clsx from 'clsx'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from '@tanstack/react-location'
import { IconButton } from '@mui/joy'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import { format } from 'date-fns'
import { formatCurrency } from '../../utils/utils'
import { toast } from 'react-toastify'
import { currencySymbols } from '../../enums/wallet'
import useSwapCurrency from '../../hooks/mutations/useSwapCurrency'
import useWalletsQuery from '../../hooks/queries/useWalletsQuery'

type Props = {
	open: boolean
	setOpen: (val: boolean) => void
}

interface UpdateInputs {
	amount: number
	rate: number
}
interface OfferInput {
	offer?: number
}

function ViewListing({ open = false, setOpen }: Props) {
	const {
		selectedListing,
		loadListings,
		setListings,
		isLoading: isMarketLoading,
	} = useMarket()

	const {
		data: walletData,
		isLoading: fetchingWallets,
		refetch: refetchWallets,
		error: walletError,
	} = useWalletsQuery()

	const { isAuthenticated, user } = useAuth()
	const [isLoading, setLoading] = useState(false)
	const [isUpdateLoading, setUpdateLoading] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const onCloseModal = () => setOpenDialog((prev) => !prev)

	const [partSell, setPartSell] = useState(selectedListing?.allow_partial_swap)
	const [isNegotiable, setIsNegotiable] = useState(
		selectedListing?.allow_negotiation
	)

	const wallets = walletData?.data.data || []
	const sourceWallet = wallets.find(
		(wallet) => wallet.currency.code === selectedListing?.source.currency
	)

	const schemaUpdate = yup.object().shape({
		amount: yup
			.number()
			.max(sourceWallet?.balance as number, 'Insufficient funds')
			.typeError('Please enter a valid amount')
			.required('Amount is required')
			.positive('Negative value is not allowed'),
		rate: yup
			.number()
			.typeError('Please enter a valid rate')
			.required('Rate is required')
			.positive('Negative value is not allowed'),
	})

	const schemaOffer = yup.object().shape({
		offer: yup
			.number()
			.typeError('Please enter a valid offer')
			.required('Please enter your offer')
			.positive('Negative value is not allowed'),
	})

	const {
		handleSubmit,
		control,
		formState: { errors },
		clearErrors,
		setError,
		watch,
		setValue,
		getValues,
	} = useForm<UpdateInputs>({
		defaultValues: {
			amount: selectedListing!.amount,
			rate: selectedListing!.rate,
		},
		resolver: yupResolver(schemaUpdate),
	})

	const {
		handleSubmit: handleOffer,
		control: offerControl,
		formState: { errors: offerError },
		clearErrors: clearOfferError,
		reset,
	} = useForm<{ offer: number }>({ resolver: yupResolver(schemaOffer) })

	useEffect(() => {
		clearErrors()
		clearOfferError()
		setValue('amount', selectedListing!.amount)
		setValue('rate', selectedListing!.rate)

		setIsNegotiable(selectedListing?.allow_negotiation || false)
		setPartSell(selectedListing?.allow_partial_swap || false)
	}, [open, selectedListing])

	const onClose = () => {
		setOpen(false)
	}

	const submitBid = async (data: OfferInput) => {
		try {
			const response = await marketApi.post(
				`/listings/${selectedListing?.id}/negotiate`,
				{ rate: data.offer }
			)
			toast.success('Your offer has been sent to the seller.')
			reset()
		} catch (error) {
			toast.error('An error has occurred')
		}
	}

	const deleteListing = async (listingId: string) => {
		setLoading(true)
		try {
			await marketApi.delete('/listing/' + listingId)
			await loadListings()
			onCloseModal()
			setLoading(false)
			setOpen(false)
			toast.success('Listing deleted')
		} catch (error) {
			setLoading(false)
			toast.error('An error has occurred')
		}
	}

	const updateListing = async (data: UpdateInputs) => {
		if (Object.keys(errors).length > 0) {
			// Errors are present, do not submit the form
			return
		}
		const updatedListing: Listing = {
			...selectedListing!,
			allow_negotiation: isNegotiable!,
			allow_partial_swap: partSell!,
			amount: data.amount,
			rate: data.rate,
			source: {
				currency: selectedListing!.source.currency,
				amount: data.amount,
			},
			destination: {
				currency: selectedListing!.destination.currency,
				amount: data.rate * data.amount,
			},
		}
		setUpdateLoading(true)
		try {
			await marketApi.put('/listings/' + selectedListing!.id, updatedListing)
			const response = await loadListings()
			if (response) {
				setListings(response)
				setUpdateLoading(false)
				setOpen(false)
			}
			toast.success('Listing updated successfully!')
		} catch (error) {
			setUpdateLoading(false)
		}
	}

	const { mutateAsync: swapCurrency, isLoading: isSwapping } = useSwapCurrency()

	const handleBuyListing = async () => {
		try {
			if (selectedListing) {
				const response = await swapCurrency({
					listingId: selectedListing?.id as string,
					amount: selectedListing?.amount,
				})

				if (response) {
					refetchWallets()
					onClose()
					toast.success('Currency swapped successfully!')
				}
			}
		} catch (error: any) {
			//onClose()
			toast.error(`${error.response.data.message}`)
		}
	}

	return (
		<>
			<Drawer
				title="Currency Listing"
				placement={'right'}
				width={500}
				onClose={onClose}
				open={open}
				closeIcon={
					<X
						size={20}
						weight="bold"
						className="cursor-pointer hover:bg-input-grey"
					/>
				}
				style={{ backgroundColor: '#f2f6fc' }}
			>
				<div className="h-52 w-full rounded-lg bg-white p-6">
					<div>
						<div className="mx-auto flex w-1/2 items-center justify-around">
							<IconButton
								size="lg"
								variant="plain"
								sx={{
									borderRadius: '50%',
									backgroundColor: '#F2F2F7',
									color: '#425C81',
									fontFamily: 'Poppins',
									fontWeight: 700,
								}}
							>
								{currencySymbols[selectedListing!.source.currency ?? '']}
							</IconButton>
							<TrendingFlatIcon />
							<IconButton
								size="lg"
								variant="plain"
								sx={{
									borderRadius: '50%',
									backgroundColor: '#F2F2F7',
									color: '#425C81',
									fontFamily: 'Poppins',
									fontWeight: 700,
								}}
							>
								{currencySymbols[selectedListing!.destination.currency ?? '']}
							</IconButton>
						</div>
						<div className="mx-auto mt-4 flex w-1/2 items-center justify-around font-poppins text-[12px]">
							{selectedListing?.created_date &&
								format(
									new Date(selectedListing?.created_date),
									'eee dd MMM yy hh:mm a'
								)}
						</div>
					</div>
					<div className="mt-8 flex justify-evenly">
						<div className="flex flex-col text-center">
							<span className="font-poppins font-medium text-grey">Amount</span>
							<span className="font-poppins text-[18px] font-semibold">
								{`${
									currencySymbols[selectedListing!.source.currency ?? '']
								} ${formatCurrency(selectedListing!.amount)}`}
							</span>
						</div>
						<div className="flex flex-col text-center">
							<span className="font-poppins font-medium text-grey">Rate</span>
							<span className="font-poppins text-[18px] font-semibold">
								{formatCurrency(selectedListing!.rate)}
							</span>
						</div>
						<div className="flex flex-col text-center">
							<span className="font-poppins font-medium text-grey">
								Negotiable
							</span>
							<span className="font-poppins text-[12px] font-thin text-grey">
								{selectedListing?.allow_negotiation ? 'Yes' : 'No'}
							</span>
						</div>
						<div className="flex flex-col text-center">
							<span className="font-poppins font-medium text-grey">
								Buy part
							</span>
							<span className="font-poppins text-[1px] font-thin text-grey">
								{selectedListing?.allow_partial_swap ? 'Yes' : 'No'}
							</span>
						</div>
					</div>
				</div>

				{isAuthenticated && user?.customerId !== selectedListing?.seller && (
					<>
						<Space
							direction="vertical"
							style={{ width: '100%', marginTop: 28 }}
						>
							<div className="flex h-12 items-center justify-start gap-2 rounded-lg border border-t-blue bg-blue bg-opacity-5 px-4 font-poppins text-[12px] text-blue">
								<InfoIcon />
								<span>
									If you buy this you spend{'  '}
									<span className="mr-1 font-bold">
										{
											currencySymbols[
												selectedListing!.destination.currency ?? ''
											]
										}{' '}
										{selectedListing &&
											formatCurrency(
												selectedListing?.amount * selectedListing?.rate
											)}
									</span>
								</span>
							</div>
						</Space>
						{selectedListing?.allow_negotiation && (
							<div className="my-8 mb-10 flex flex-col gap-3">
								<span className="font-poppins font-medium text-grey/80">
									NEGOTIATE EXCHANGE RATE
								</span>
								<div className="flex flex-col gap-2">
									<label className="font-poppins text-grey/80">Offer</label>
									<Controller
										name="offer"
										control={offerControl}
										render={({ field }) => (
											<Input
												{...field}
												placeholder="Your offer"
												type="number"
												className={clsx(
													offerError.offer && 'border border-error'
												)}
												allowClear
											/>
										)}
									/>
									{offerError.offer && (
										<span className="font-poppins text-[10px] text-error">
											{offerError.offer.message}
										</span>
									)}
								</div>
							</div>
						)}

						<div className=" mt-6 flex flex-col gap-4">
							{selectedListing?.allow_negotiation && (
								<Button
									size="compact"
									text="Submit bid"
									fullWidth
									variant="secondary"
									onClick={handleOffer(submitBid)}
								/>
							)}
							<Button
								size="compact"
								text="Buy"
								fullWidth
								variant="primary"
								isLoading={isSwapping}
								onClick={handleBuyListing}
							/>
						</div>
					</>
				)}

				{isAuthenticated && user?.customerId === selectedListing?.seller && (
					<>
						<Space
							direction="vertical"
							style={{ width: '100%', marginTop: 28 }}
						>
							<div className="flex h-12 items-center justify-start gap-2 rounded-lg border border-t-blue bg-blue bg-opacity-5 px-4 font-poppins text-[12px] text-blue">
								<InfoIcon />
								<span>
									If you sell this you get{'  '}
									<span className="mr-1 font-bold">
										{
											currencySymbols[
												selectedListing!.destination.currency ?? ''
											]
										}{' '}
										{selectedListing &&
											formatCurrency(
												selectedListing?.amount * selectedListing?.rate
											)}
									</span>
								</span>
							</div>
						</Space>
						<div className="my-8 mb-10 flex flex-col gap-3">
							<span className="font-poppins text-grey/80">EDIT LISTING</span>
							<div className="flex flex-col gap-2">
								<label className="font-poppins text-grey/80">Amount</label>
								<Controller
									name="amount"
									control={control}
									render={({ field }) => (
										<Input
											{...field}
											placeholder="Amount"
											type="number"
											className={clsx(errors.amount && 'border border-error')}
											allowClear
										/>
									)}
								/>
								{errors.amount && (
									<span className="font-poppins text-[10px] text-error">
										{errors.amount.message}
									</span>
								)}
							</div>
							<div className="flex flex-col gap-2">
								<label className="font-poppins text-grey/80">
									Exchange rate
								</label>
								<Controller
									name="rate"
									control={control}
									render={({ field }) => (
										<Input
											{...field}
											placeholder="Exchange rate"
											type="number"
											className={clsx(errors.rate && 'border border-error')}
											allowClear
										/>
									)}
								/>
								{errors.rate && (
									<span className="font-poppins text-[10px] text-error">
										{errors.rate.message}
									</span>
								)}
							</div>
						</div>
						<div className="mb-10">
							<div className="mb-4 flex items-center justify-between">
								<span className="font-poppins">Willing to negotiate</span>
								<CustomSwitch
									checked={isNegotiable!}
									onChange={(e) => setIsNegotiable(e.target.checked)}
								/>
							</div>
							<div className="mb-4 flex items-center justify-between">
								<span className="font-poppins">Willing to part-sell</span>
								<CustomSwitch
									checked={partSell!}
									onChange={(e) => setPartSell(e.target.checked)}
								/>
							</div>
						</div>

						<div className=" flex flex-col gap-4">
							<Button
								size="compact"
								text="Update this Listing"
								fullWidth
								variant="secondary"
								isLoading={isUpdateLoading}
								onClick={handleSubmit(updateListing)}
							/>
							<Button
								size="compact"
								text="Delete this listing"
								fullWidth
								variant="primary"
								className=" bg-error"
								onClick={() => {
									setOpen(false)
									setOpenDialog(true)
								}}
							/>
						</div>
					</>
				)}

				{!isAuthenticated && (
					<Space direction="vertical" style={{ width: '100%', marginTop: 28 }}>
						<div className="flex h-12 items-center justify-start gap-2 rounded-lg border border-t-blue bg-blue bg-opacity-5 px-4 font-poppins text-[12px] text-blue">
							<InfoIcon />
							<span>
								Limited access!{' '}
								<Link to={'/auth/sign-in'} className="mr-1 font-bold underline">
									Please login
								</Link>{' '}
								or
								<Link
									to={'/auth/create-account'}
									className="ml-1 font-bold underline"
								>
									create an account
								</Link>
							</span>
						</div>
					</Space>
				)}
			</Drawer>
			<Dialog
				isOpen={openDialog}
				onClose={() => setOpenDialog((prev) => !prev)}
			>
				<Dialog.Title
					as="h3"
					className="text-lg text-gray-600 flex font-extrabold leading-6"
				>
					{/* <WarningIcon className="mr-2 text-error" /> */}
					Delete listing
				</Dialog.Title>
				<Dialog.Description>
					<div className="mt-2">
						<div className="text-gray-500 text-[12px] font-normal">
							<span className="">You are about to delete a listing.</span>{' '}
							<br />
							Are you sure?
						</div>
					</div>

					<div className="mt-4 flex gap-6">
						<button
							type="button"
							className="border-transparent bg-blue-100 hover:bg-blue-200 inline-flex  justify-center rounded-md border border-blue px-4 py-2 text-[12px] font-normal text-blue  focus:outline-none"
							onClick={onCloseModal}
						>
							No, thanks!
						</button>
						<button
							type="button"
							className="border-transparent bg-blue-100 hover:bg-blue-200 inline-flex  justify-center rounded-md border border-error px-4 py-2 text-[12px] font-normal text-error  focus:outline-none"
							onClick={() => deleteListing(selectedListing?.id!)}
						>
							Yes, Delete.
						</button>
					</div>
				</Dialog.Description>
			</Dialog>
		</>
	)
}

export default ViewListing
