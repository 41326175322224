import { lazy, useState, Suspense } from 'react'
import FullPageSpinner from '../../../components/Feedback/Spinner/FullPageSpinner'
import useApi from '../../../hooks/useApi'
import useMultistepForm from '../../../hooks/useMultistepForm'
import { manageSessionStorage } from '../../../utils/manageSessionStorage'

const VerifyMobile = lazy(() => import('./VerifyMobile/VerifyMobile'))
const EnterOtp = lazy(() => import('./EnterOtp/EnterOtp'))
const CreatePassword = lazy(() => import('./CreatePassword/CreatePassword'))
const CreateProfile = lazy(() => import('./CreateProfile/CreateProfile'))
const AccountEmailVerification = lazy(
	() => import('./AccountEmailVerification/AccountEmailVerification')
)

type SignUpData = {
	firstname: string
	lastname: string
	middlename?: string
	country: string
	phone: string
	address: string
	email: string
	dob: string
	password: string
	postcode: string
}

const initialData: SignUpData = {
	firstname: '',
	lastname: '',
	middlename: '',
	country: '',
	phone: '',
	address: '',
	email: '',
	dob: '',
	password: '',
	postcode: '',
}

const CreateAccount = () => {
	const api = useApi()
	const [signUpData, setSignUpData] = useState(initialData)
	const [currentStepIndex, setCurrentStepIndex] = useState(0)

	const nextStep = () => {
		setCurrentStepIndex((i) => {
			if (i >= steps.length - 1) return i
			return i + 1
		})
	}

	const previousStep = () => {
		setCurrentStepIndex((i) => {
			if (i <= 0) return i
			return i - 1
		})
	}

	const formFieldsHandler = (formFields: Partial<SignUpData>) => {
		setSignUpData((previous) => {
			return { ...previous, ...formFields }
		})
	}

	const completeProfileHandler = async () => {
		const response = await api.post('/auth/register', signUpData)
		manageSessionStorage.set('accessToken', response.data.token)
	}

	const { step, steps } = useMultistepForm(
		[
			<VerifyMobile
				{...signUpData}
				formFieldsHandler={formFieldsHandler}
				nextStep={nextStep}
				previousStep={previousStep}
			/>,
			<EnterOtp nextStep={nextStep} previousStep={previousStep} />,
			<CreatePassword
				{...signUpData}
				formFieldsHandler={formFieldsHandler}
				nextStep={nextStep}
				previousStep={previousStep}
			/>,
			<CreateProfile
				{...signUpData}
				formFieldsHandler={formFieldsHandler}
				completeProfileHandler={completeProfileHandler}
				nextStep={nextStep}
			/>,
			<AccountEmailVerification />,
		],
		currentStepIndex
	)

	// return (
	// 	<Suspense>
	// 		<EnterOtp nextStep={nextStep} previousStep={previousStep} />
	// 	</Suspense>
	// )
	return <Suspense fallback={<FullPageSpinner isOpaque />}>{step}</Suspense>
}

export default CreateAccount
