import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { LoginRequest } from '../api/models/LoginRequest'
import { UserProfile } from '../api/models/UserProfile'
import type { ReactElement, ReactNode } from 'react'
import axios, { AxiosError } from 'axios'
import { ApiError } from '../api/models/ApiError'
import useApi from '../hooks/useApi'
import { manageSessionStorage } from '../utils/manageSessionStorage'

export interface AuthContextType {
	initializingAuth: boolean
	user?: UserProfile
	isAuthenticated: boolean
	login: (loginData: LoginRequest) => Promise<void>
	logout: () => void
	error?: AxiosError<ApiError> | null
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

const AuthProvider = ({
	children,
}: {
	children: ReactNode | ReactNode[]
}): ReactElement => {
	const [user, setUser] = useState<UserProfile | undefined>(undefined)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<AxiosError<ApiError>>()

	const loadUser = async (): Promise<UserProfile | undefined> => {
		setIsLoading(true)
		try {
			const api = useApi()
			const response = await api.get('/auth')
			return response.data.user
		} catch (error) {
			if (axios.isAxiosError(error)) {
				setUser(undefined)
				manageSessionStorage.clearTokenFromStorage()
			}
		} finally {
			setIsLoading(false)
		}
	}

	const login = async (loginData: LoginRequest) => {
		setIsLoading(true)
		try {
			const api = useApi()
			const response = await api.post('/auth/login', loginData)
			manageSessionStorage.set('accessToken', response.data.user.token)

			setUser(response.data.user)
		} catch (error) {
			if (axios.isAxiosError(error)) setError(error)
		} finally {
			setIsLoading(false)
		}
	}

	const logout = (): void => {
		manageSessionStorage.clearTokenFromStorage()
		setUser(undefined)
	}

	useEffect(() => {
		loadUser().then((user) => setUser(user))
	}, [])

	const context = useMemo(() => {
		return {
			initializingAuth: isLoading,
			isAuthenticated: user !== undefined,
			user,
			login,
			logout,
			error,
		}
	}, [login, user, isLoading])

	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

const useAuth = (): AuthContextType => useContext<AuthContextType>(AuthContext)

export { AuthProvider, useAuth }
