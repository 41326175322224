import axios from 'axios'
import { manageSessionStorage } from '../utils/manageSessionStorage'

const appUrl = `http://localhost:5050/api/v1`
const token = manageSessionStorage.get('accessToken')

const api = axios.create({
	baseURL: appUrl,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
})

const marketApi = axios.create({
	baseURL: 'http://localhost:5000',
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${token}`,
	},
})

const setToken = async () => {
	try {
		api.defaults.headers.common['x-auth-token'] = token
	} catch (error) {
		console.log(error)
	}
}
setToken()

export { marketApi }
export default api
