import { Bank, Student } from '@phosphor-icons/react'
import clsx from 'clsx'
interface Props {
	className?: string
}
function Services({ className }: Props) {
	return (
		<div className={clsx('mb-6 flex flex-col gap-2', className && className)}>
			<span className="mb-1">Services</span>
			<div className="grid grid-cols-2 gap-2">
				<div className="flex cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-new-grey bg-white p-4 text-center text-[0.75rem] hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm">
					<span className="text-blue">
						<Student size={32} weight="fill" />
					</span>
					<span>Tuition payment</span>
				</div>
				<div className=" flex cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-new-grey bg-white p-4 text-center text-[0.75rem] hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm">
					<span className="text-blue">
						<Bank size={32} weight="fill" />
					</span>
					<span>Other payments</span>
				</div>
			</div>
		</div>
	)
}

export default Services
