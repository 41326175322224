import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useNavigate } from '@tanstack/react-location'
import { useAuth } from '../../../contexts/AuthProvider'

const MenuDropDown = () => {
	const navigate = useNavigate()
	const { user, logout, initializingAuth } = useAuth()
	const handleNavigate = (location: string) => navigate({ to: location })
	return (
		<>
			<Menu as="div" className="relative inline-block text-left font-poppins">
				<div>
					<Menu.Button className="text-sm inline-flex w-full justify-center rounded-md border-0 bg-[#f2f2f2] px-4 py-2 hover:bg-[#ecececf8]">
						<span className=" text-xl relative  flex h-6 w-6 items-center justify-center rounded-full text-white">
							<img
								src="http://source.unsplash.com/100x100/?girl"
								className="rounded-full"
							/>
						</span>
						<ChevronDownIcon
							className="text-violet-200 hover:text-violet-100 ml-2 -mr-1 h-5 w-5"
							aria-hidden="true"
						/>
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className=" ring-black absolute right-0 mt-2 w-56 origin-top-right divide-y rounded-md border border-[#ccc] bg-white shadow-lg">
						<div className="px-1 py-1 ">
							<Menu.Item>
								{({ active }) => (
									<button
										className={`${
											active ? 'bg-white text-blue' : 'text-gray-900'
										} text-sm group flex w-full items-center rounded-md px-2 py-2`}
										onClick={() => handleNavigate('/user/account')}
									>
										My Account
									</button>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={() => handleNavigate('/help')}
										className={`${
											active ? ' text-blue' : 'text-gray-900'
										} text-sm group flex w-full items-center rounded-md px-2 py-2`}
									>
										Help
									</button>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={() => handleNavigate('/user/settings')}
										className={`${
											active ? ' text-blue' : 'text-gray-900'
										} text-sm group flex w-full items-center rounded-md px-2 py-2`}
									>
										Settings
									</button>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={() => logout()}
										className={`${
											active ? ' text-blue' : 'text-gray-900'
										} text-sm text-gray-900 group flex w-full items-center rounded-md px-2 py-2`}
									>
										Logout
									</button>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		</>
	)
}

export default MenuDropDown
