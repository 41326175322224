import { useEffect, useState } from 'react'
import Dialog from '../../components/Feedback/Dialog/Dialog'
import { useAuth } from '../../contexts/AuthProvider'
import Header from '../../components/Globals/Header/Header'
import FullPageSpinner from '../../components/Feedback/Spinner/FullPageSpinner'
import Container from '../../components/Globals/Container/Container'
import AppBar from '../../components/Globals/AppBar/AppBar'
import InfoIcon from '@mui/icons-material/Info'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'

import useGreeting from '../../hooks/useGreeting'
import { capitalizeFirstLetter } from '../../utils/utils'
import useWalletsQuery from '../../hooks/queries/useWalletsQuery'
import { Wallet } from '../../api/models/market/Wallets'
import WalletSelection from '../../components/WalletPanel/WalletSelection'
import WalletPanel from '../../components/WalletPanel/WalletPanel'
import Services from '../../components/Services/Services'
import { Space } from 'antd'

const Home = () => {
	const { user, logout, initializingAuth, isAuthenticated } = useAuth()
	const {
		data: queryData,
		isLoading: fetchingWallets,
		refetch: refetchWallets,
		error: walletError,
	} = useWalletsQuery()

	const accounts = queryData?.data.data || []

	const [openDialog, setOpenDialog] = useState(
		!user?.isDocumentVerified as boolean
	)

	const [selectedAccount, setSelectedAccount] = useState<Wallet>(accounts[0])
	const [openAccountsDialog, setOpenAccountsDialog] = useState(false)

	useEffect(() => {
		if (!localStorage.getItem('selectedAccountCurrencyCode')) {
			localStorage.setItem(
				'selectedAccountCurrencyCode',
				selectedAccount?.currency?.code
			)
		}
	}, [selectedAccount])

	useEffect(() => {
		const [getSelectedAccount] = accounts.filter(
			(account) =>
				account.currency.code ===
				localStorage.getItem('selectedAccountCurrencyCode')
		)
		setSelectedAccount(getSelectedAccount)
	}, [fetchingWallets, accounts])

	const selectHandler = (selectedAccount: Wallet) => {
		setSelectedAccount(selectedAccount)
		localStorage.setItem(
			'selectedAccountCurrencyCode',
			selectedAccount?.currency?.code
		)
		setOpenAccountsDialog(false)
	}

	const onClose = () => setOpenDialog((prev) => !prev)

	if (walletError) {
		refetchWallets()
	}

	if (initializingAuth || fetchingWallets) return <FullPageSpinner />

	return (
		<>
			<Header />
			<div className="mb-16 mt-16 min-h-screen p-4 font-poppins md:mt-20">
				<Container fullHeight>
					<div className="md:mt-8 md:flex md:justify-between md:gap-4">
						{/**left */}
						<div className="md:fixed md:w-2/6 md:min-w-[360px] md:max-w-[360px]">
							<div className="mb-6">
								<div className="mb-4 font-semibold">
									{useGreeting(capitalizeFirstLetter(user?.firstname || ''))}
								</div>
								<WalletPanel
									selectedAccount={selectedAccount!}
									setOpenAccountsDialog={setOpenAccountsDialog}
									isHomePage
								/>
							</div>
							{/* switch wallets */}
							{/* <div className="mb-6 flex h-40 w-full flex-col gap-2 rounded-sm shadowmdm-t-4 border-t-blue bg-white p-4 shadow-md">
								Switch
							</div> */}

							{/* {services} */}
							<Services />
						</div>

						{/**right */}
						<div className="md:ml-[380px] md:flex md:w-4/6 md:flex-col">
							{/* transactions */}
							<div>
								<Space
									direction="vertical"
									style={{ width: '100%', marginTop: 28, marginBottom: 18 }}
								>
									<div className="flex h-12 items-center justify-start gap-2 rounded-lg border border-t-blue bg-blue bg-opacity-5 px-4 font-poppins text-[12px] text-blue">
										<InfoIcon />
										<span>
											This feature is currently under development{'  '}
											<span className="mr-1 font-bold"></span>
										</span>
									</div>
								</Space>
								<div className="mb-3 flex items-center justify-between">
									<span>Recent transactions</span>
									<span className="text-[0.75rem] font-semibold">View all</span>
								</div>

								<div className="flex flex-col gap-2">
									<div className=" flex w-full cursor-pointer justify-between rounded-md bg-white p-2 shadow-sm hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm">
										<div className="flex flex-col gap-1">
											<span className="text-[0.5rem]">6 April 2023</span>
											<div className="flex flex-col gap-1">
												<span className="text-[0.75rem] font-bold text-error">
													- GBP 200
												</span>
												<span className="text-[0.60rem] font-normal text-grey">
													+ NGN 180,000
												</span>
											</div>
										</div>
										<div className="flex flex-col">
											<span className="text-grey/70">NGN 900</span>
											<span className="text-[0.70rem] text-grey">1 GBP</span>
										</div>
									</div>
									<div className="roundedmdm flex w-full cursor-pointer justify-between rounded-md bg-white p-2 shadow-sm hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm">
										<div className="flex flex-col gap-1">
											<span className="text-[0.5rem]">6 April 2023</span>
											<div className="flex flex-col gap-1">
												<span className="text-[0.75rem] font-bold text-error">
													- NGN 1,800,000
												</span>
												<span className="text-[0.60rem] font-normal text-grey">
													+ GBP 2,200
												</span>
											</div>
										</div>
										<div className="flex flex-col">
											<span>NGN 910</span>
											<span className="text-[0.70rem] text-grey">1 GBP</span>
										</div>
									</div>
									<div className="roundedmdm flex w-full cursor-pointer justify-between rounded-md bg-white p-2 shadow-sm hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm">
										<div className="flex flex-col gap-1">
											<span className="text-[0.5rem]">6 April 2023</span>
											<div className="flex flex-col gap-1">
												<span className="text-[0.75rem] font-bold text-error">
													- GBP 800
												</span>
												<span className="text-[0.60rem] font-normal text-grey">
													+ NGN 716,000
												</span>
											</div>
										</div>
										<div className="flex flex-col">
											<span>NGN 895</span>
											<span className="text-[0.70rem] text-grey">1 GBP</span>
										</div>
									</div>
									<div className="flex w-full cursor-pointer justify-between rounded-md bg-white p-2 shadow-sm hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm">
										<div className="flex flex-col gap-1">
											<span className="text-[0.5rem]">6 April 2023</span>
											<div className="flex flex-col gap-1">
												<span className="text-[0.75rem] font-bold text-error">
													- GBP 50
												</span>
												<span className="text-[0.60rem] font-normal text-grey">
													+ NGN 46,000
												</span>
											</div>
										</div>
										<div className="flex flex-col">
											<span>NGN 920</span>
											<span className="text-[0.70rem] text-grey">1 GBP</span>
										</div>
									</div>
									<div className="roundedmdm flex w-full cursor-pointer justify-between rounded-md bg-white p-2 shadow-sm hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm">
										<div className="flex flex-col gap-1">
											<span className="text-[0.5rem]">6 April 2023</span>
											<div className="flex flex-col gap-1">
												<span className="text-[0.75rem] font-bold text-error">
													- NGN 48,000
												</span>
												<span className="text-[0.60rem] font-normal text-grey">
													+ GBP 54.54
												</span>
											</div>
										</div>
										<div className="flex flex-col">
											<span>NGN 880</span>
											<span className="text-[0.70rem] text-grey">1 GBP</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
			<Dialog
				isOpen={openAccountsDialog}
				onClose={() => setOpenAccountsDialog(false)}
				className=" bg-white p-2"
			>
				<Dialog.Title
					as="h3"
					className="text-lg text-gray-600 mb-4 flex font-extrabold leading-6"
				>
					<AccountBalanceWalletIcon className="mr-2 text-blue" />
					Your wallets
				</Dialog.Title>
				<Dialog.Description>
					<WalletSelection
						accounts={accounts}
						selected={selectedAccount}
						setSelected={selectHandler}
					/>
				</Dialog.Description>
			</Dialog>
			<Dialog
				isOpen={openDialog}
				onClose={() => setOpenDialog((prev) => !prev)}
			>
				<Dialog.Title
					as="h3"
					className="text-lg text-gray-600 flex font-extrabold leading-6"
				>
					<InfoIcon className="mr-2 text-blue" />
					Pending document verification
				</Dialog.Title>
				<Dialog.Description>
					<div className="mt-2">
						<p className="text-gray-500 text-[12px]">
							You have a pending document verification. Please upload your
							documents before you can perform any transaction.
						</p>
					</div>

					<div className="mt-4">
						<button
							type="button"
							className="border-transparent bg-blue-100 text-sm hover:bg-blue-200 inline-flex justify-center rounded-md border-blue px-4 py-2 font-normal text-blue shadow-sm  focus:outline-none"
							onClick={onClose}
						>
							Got it, thanks!
						</button>
					</div>
				</Dialog.Description>
			</Dialog>
			<AppBar />
		</>
	)
}

export default Home
