import { customers } from '../data/customer'
import { mockWallets } from '../data/wallets'

export const mockGetWalletsController = (req, res, ctx) => {
	const header = req.headers.get('authorization')
	const [bearer, mockToken] = header.split(' ')
	const user = customers.find((customer) => customer.token === mockToken)
	if (mockToken && user) {
		return res(
			ctx.delay(300),
			ctx.status(200),
			ctx.json({
				status: '00',
				message: 'Ok',
				paginate: {
					total_records: 2,
					total_pages: 1,
					page_size: 20,
					page_number: 1,
				},
				data: mockWallets[user.customerId],
			})
		)
	}

	return res(ctx.delay(300), ctx.status(401))
}

export const mockAddMoneyController = (req, res, ctx) => {
	const header = req.headers.get('authorization')
	const [bearer, mockToken] = header.split(' ')
	const { currencyCode, amount, cardNo, cardCvv } = req.body
	const user = customers.find((customer) => customer.token === mockToken)

	if (cardNo !== '5555 4444 3333 2222' && cardCvv !== '123') {
		return res(
			ctx.delay(250),
			ctx.status(400),
			ctx.json({ message: 'Invalid card ' })
		)
	}

	if (mockToken && user) {
		const userWallets = mockWallets[user.customerId]
		if (userWallets) {
			const userWalletsUpdated = userWallets.map((wallet) => {
				if (wallet?.currency?.code === currencyCode) {
					return {
						...wallet,
						balance: wallet.balance + amount,
					}
				}

				return wallet
			})

			mockWallets[user.customerId] = userWalletsUpdated
			return res(
				ctx.delay(300),
				ctx.status(200),
				ctx.json({
					status: '00',
					message: 'Ok',
				})
			)
		}
	}

	return res(
		ctx.delay(250),
		ctx.status(400),
		ctx.json({ message: 'Unauthorized' })
	)
}
