import { useAuth } from '../../contexts/AuthProvider'
import Header from '../../components/Globals/Header/Header'
import FullPageSpinner from '../../components/Feedback/Spinner/FullPageSpinner'
import FormLabel from '@mui/joy/FormLabel'
import Radio, { radioClasses } from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup'
import Sheet from '@mui/joy/Sheet'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { IconButton } from '@mui/joy'
import PaymentIcon from '@mui/icons-material/Payment'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AppBar from '../../components/Globals/AppBar/AppBar'
import { useEffect, useState } from 'react'
import { Wallet } from '../../api/models/market/Wallets'
import useWalletsQuery from '../../hooks/queries/useWalletsQuery'
import TextInputFieldWithLabel from '../../components/Inputs/TextInput/TextInputFieldWithLabel'
import Button from '../../components/Inputs/Button/Button'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import BankAccount from './components/BankAccount'
import Card from './components/Card'
import { CurrencyCode, currencySymbols } from '../../enums/wallet'
import { TransactionData } from '../../types/transaction'
import { checkIfNumber } from '../../utils/utils'
import { motion, AnimatePresence } from 'framer-motion'

type CreditAmountFormValues = {
	amount: number
}

const creditAmountSchema = yup.object().shape({
	amount: yup.number().required(),
})

const PAYMENT_TYPES = [
	{ id: 1, type: 'Card' },
	{ id: 2, type: 'Bank account' },
]

const AddMoney = () => {
	const { initializingAuth } = useAuth()
	const { data: queryData, isLoading: fetchingWallets } = useWalletsQuery()

	const accounts = queryData?.data.data || []
	const [selected, setSelected] = useState(PAYMENT_TYPES[0])
	const [selectedAccount, setSelectedAccount] = useState<Wallet>(accounts[0])

	const [transactionData, setTransactionData] = useState<TransactionData>({
		amount: 0,
		type: 'Card',
		currencyCode: 'GBP',
	})

	const [paymentSteps, setPaymentSteps] = useState<
		'Amount' | 'Card' | 'Bank' | 'paymentComplete'
	>('Amount')

	useEffect(() => {
		const selectedAcctCode: CurrencyCode = localStorage.getItem(
			'selectedAccountCurrencyCode'
		) as CurrencyCode

		const [getSelectedAccount] = accounts.filter(
			(account) =>
				account.currency.code ===
				localStorage.getItem('selectedAccountCurrencyCode')
		)
		if (getSelectedAccount) {
			setSelectedAccount(getSelectedAccount)
		}
	}, [fetchingWallets, accounts])

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<CreditAmountFormValues>({
		resolver: yupResolver(creditAmountSchema),
	})

	const proceedPayment = (data: CreditAmountFormValues) => {
		setTransactionData({
			amount: data.amount,
			currencyCode: selectedAccount.currency.code,
			type: selected.type as 'Card' | 'Bank account',
		})

		if (selected.type === 'Card') {
			setPaymentSteps('Card')
		}

		if (selected.type === 'Bank account') {
			setPaymentSteps('Bank')
		}
	}

	if (initializingAuth) return <FullPageSpinner />

	const renderPaymentStep = () => {
		switch (paymentSteps) {
			case 'Card':
				return <Card transactionData={transactionData} />

			case 'Bank':
				return <BankAccount />

			case 'paymentComplete':
				return <></>

			default:
				return (
					<AnimatePresence>
						<motion.div
							className="flex w-full flex-col gap-6 px-4 md:w-1/2 md:max-w-[500px]"
							initial={{ x: -100, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							exit={{ x: -100, opacity: 0 }}
						>
							{selectedAccount?.currency && (
								<div className="mb-4">
									You're about to add money to your{' '}
									<span className="font-bold">{`${selectedAccount.currency.code}`}</span>{' '}
									wallet
								</div>
							)}
							<RadioGroup
								aria-label="platform"
								defaultValue="Website"
								overlay
								name="platform"
								sx={{
									flexDirection: 'row',
									gap: 2,
									[`& .${radioClasses.checked}`]: {
										[`& .${radioClasses.action}`]: {
											inset: -1,
											border: '3px solid',
											borderColor: '#425C81',
											outline: 'none',
										},
									},
									[`& .${radioClasses.radio}`]: {
										display: 'contents',
										'& > svg': {
											zIndex: 2,
											position: 'absolute',
											top: '-8px',
											right: '-8px',
											bgcolor: '#425C81',
											borderRadius: '50%',
											color: '#425C81',
											backgroundColor: 'white',
										},
									},
								}}
							>
								{PAYMENT_TYPES.map((paymentType, index) => (
									<Sheet
										key={paymentType.id}
										variant="outlined"
										sx={{
											borderRadius: 'md',
											bgcolor: 'background.body',
											boxShadow: 'sm',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											gap: 1.5,
											p: 2,
											minWidth: 140,
											width: '100%',
											fontFamily: 'Poppins',
											outline: 'none',
										}}
									>
										<Radio
											id={paymentType.type}
											value={paymentType.type}
											checkedIcon={<CheckCircleRoundedIcon />}
											onChange={() => setSelected(paymentType)}
											checked={
												!selected
													? index === 0
													: selected?.id === paymentType.id
											}
										/>

										<IconButton
											size="lg"
											variant="plain"
											sx={{
												borderRadius: '50%',
												backgroundColor: '#F2F2F7',
												color: '#425C81',
												fontFamily: 'Poppins',
												fontWeight: 700,
											}}
										>
											{paymentType.type === 'Card' ? (
												<PaymentIcon />
											) : (
												<AccountBalanceIcon />
											)}
										</IconButton>

										<FormLabel
											htmlFor={paymentType.type}
											sx={{ fontFamily: 'Poppins' }}
										>
											{paymentType.type}
										</FormLabel>
									</Sheet>
								))}
							</RadioGroup>
							<div className="min-h-32 flex w-full min-w-[150px] flex-col gap-5 rounded-md bg-white p-4 shadow-lg">
								<TextInputFieldWithLabel
									type="text"
									label={
										currencySymbols[selectedAccount?.currency?.code] || 'Amount'
									}
									id="amount"
									{...(errors.amount && { error: true })}
									{...register('amount')}
									onKeyDown={(event) => checkIfNumber(event)}
								/>

								<Button
									text="Proceed"
									title="Proceed"
									fullWidth
									variant="primary"
									onClick={handleSubmit(proceedPayment)}
								/>
							</div>
						</motion.div>
					</AnimatePresence>
				)
		}
	}

	return (
		<>
			<Header />

			<div className="flex h-screen w-full min-w-[150px] items-center justify-center">
				{renderPaymentStep()}
			</div>

			<AppBar />
		</>
	)
}

export default AddMoney
