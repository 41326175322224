export const manageSessionStorage = {
	get(key: string) {
		return sessionStorage.getItem(key)
	},
	set(key: string, value: string) {
		sessionStorage.setItem(key, value)
	},

	clearTokenFromStorage() {
		sessionStorage.clear()
	},
}
