export const mockEnpoints = {
	login: '*/login',
	getWallets: '*/wallets',
	getListings: '*/listings',
	getCurrencies: '*/currencies',
	getAuthUser: '*/auth',
	addListing: '*/listings',
	updateListing: '*/listings/:listingId',
	deleteListing: '*/listing/:listingId',
	sendOtp: '*/auth/send-mobile-otp',
	verifyMobile: '*/auth/verify-mobile-otp',
	getPostCodeAddress: '*/auth/get-postcode-addresses',
	register: '*/auth/register',
	fundWallet: '*/wallets/add-money',
	swapListing: '*/listings/:listingId/swap',
}
