
import type { LinkProps } from '@tanstack/react-location'
import { Link } from '@tanstack/react-location'
import clsx from 'clsx'
import { CaretLeft, X } from 'phosphor-react'
import type { ReactElement, ReactNode } from 'react'




interface SimpleHeaderBackButtonProperties {
	text: string
	onClick: () => void
}

const BackButton = ({
	text,
	onClick
}: SimpleHeaderBackButtonProperties): ReactElement => (
	<button
		type='button'
		onClick={onClick}
		className='-ml-2 rounded-lg p-2 hover:bg-light-grey hover:underline '
	>
		<CaretLeft size={32} weight="bold" className='mr-2'/>
	
		{text}
	</button>
)

interface SimpleHeaderBackLinkProperties extends LinkProps {
	text: string
}

const BackLink = ({
	text,
	...restProperties
}: SimpleHeaderBackLinkProperties): ReactElement => (
	<Link
		{...restProperties}
		className='-ml-2 rounded-lg p-2 hover:bg-light-grey hover:underline'
	>
		<CaretLeft size={32} weight="bold" className='mr-2'/>
		{text}
	</Link>
)

const ExitButton = (
	properties: React.ButtonHTMLAttributes<HTMLButtonElement>
): ReactElement => {
	
	return (
		<button
			type='button'
			{...properties}
			className={clsx(
				'ml-auto -mr-2 flex h-10 w-10 items-center justify-center rounded-lg p-2 hover:bg-light-grey',
			)}
		>
			<X size={32} weight="bold" />
		</button>
	)
}

const ExitLink = (properties: LinkProps): ReactElement => {
	return (
		<Link
			{...properties}
			className={clsx(
				'ml-auto -mr-2 flex h-10 w-10 items-center justify-center rounded-lg p-2 hover:bg-light-grey' 
			)}
		>
			<X size={32} weight="bold" />
		</Link>
	)
}

interface SimpleHeaderProperties {
	className?: string
	children?: ReactNode | ReactNode[]
}

const SimpleHeader = ({
	className,
	children
}: SimpleHeaderProperties): ReactElement => {
	
	return (
		<nav
			className={clsx(
				'flex h-14 items-center px-4 text-subhead font-semibold shadow sm:h-[4.5rem] sm:text-body bg-white',
				className
			)}
		>
		{children}
		</nav>
	)
}

SimpleHeader.BackButton = BackButton
SimpleHeader.BackLink = BackLink
SimpleHeader.ExitButton = ExitButton
SimpleHeader.ExitLink = ExitLink

export default SimpleHeader
