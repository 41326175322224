import { useAuth } from '../../contexts/AuthProvider'
import Header from '../../components/Globals/Header/Header'
import FullPageSpinner from '../../components/Feedback/Spinner/FullPageSpinner'
import Container from '../../components/Globals/Container/Container'
import AppBar from '../../components/Globals/AppBar/AppBar'
import useGreeting from '../../hooks/useGreeting'
import { capitalizeFirstLetter } from '../../utils/utils'
import Disclosure from '../../components/Disclosure/Disclosure'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { IconButton } from '@mui/joy'
import BugReportIcon from '@mui/icons-material/BugReport'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import EmailIcon from '@mui/icons-material/Email'
import { useState } from 'react'
import clsx from 'clsx'

const Help = () => {
	const { user, isAuthenticated, initializingAuth } = useAuth()
	const [showSupport, setShowSupport] = useState(false)

	const handleShowSupport = () => setShowSupport((prev) => !prev)

	if (initializingAuth) return <FullPageSpinner />

	return (
		<>
			<Header />
			<div className="relative mt-16 mb-16 min-h-screen p-4 font-poppins md:mt-20">
				<Container fullHeight>
					<div className="mb-4 flex items-center justify-between font-semibold">
						<div>
							{isAuthenticated &&
								useGreeting(capitalizeFirstLetter(user?.firstname || ''))}
						</div>
						<span className="md:hidden">
							<IconButton
								size="lg"
								variant="plain"
								sx={{
									borderRadius: '50%',
									backgroundColor: '#FFFFFF',
									color: '#425C81',
									fontFamily: 'Poppins',
									fontWeight: 700,
								}}
								onClick={handleShowSupport}
							>
								<SupportAgentIcon />
							</IconButton>
						</span>
					</div>
					<div>
						<h1 className="font-poppins text-[18px] font-extrabold">
							Hi, how can we help?
						</h1>
						<div className="grid md:gap-4">
							<div className="">
								<h3 className="mt-8 mb-3">Complaints</h3>
								<div className="flex flex-col gap-1">
									<Disclosure title="Dispute Management">
										<div>This is the content for dispute management</div>
									</Disclosure>
									<Disclosure title="ID Verification">
										<div>This is the content for dispute management</div>
									</Disclosure>
								</div>
								<h3 className="mt-8 mb-3">Frequent Questions</h3>
								<div className="flex flex-col gap-1">
									<Disclosure title="FAQs">
										<div>FAQs</div>
									</Disclosure>
									<Disclosure title="Terms and Conditions">
										<div>T&C</div>
									</Disclosure>
									<Disclosure title="Privacy Policy">
										<div>P&P</div>
									</Disclosure>
									<Disclosure title="GDPR Policy">
										<div>GDPR</div>
									</Disclosure>
								</div>

								<h3 className="mt-8 mb-3">Others</h3>
								<div className="flex flex-col gap-1">
									<Disclosure title="About Diaspay">
										<div>About Diaspay</div>
									</Disclosure>
								</div>
							</div>
							<div
								className={clsx(
									' h-full w-full bg-white p-4 md:col-span-5 md:block md:h-[300px] md:w-1/4 md:rounded-md',
									showSupport ? '' : 'hidden'
								)}
							>
								<span>Support</span>
								<div className="mt-8 flex justify-center gap-8">
									<button
										className="flex flex-col items-center gap-2 rounded-md border border-new-grey bg-lemon/10 p-4"
										onClick={() => alert('Raise a complaint')}
									>
										<BugReportIcon className="text-grey" />
										<span className="font-poppins text-[14px]">
											Raise a complaint
										</span>
									</button>
									<button
										className="flex flex-col items-center gap-2 rounded-md border border-new-grey bg-lemon/10 p-4"
										onClick={() => alert('Contact live support')}
									>
										<LiveHelpIcon className="text-grey" />
										<span className="font-poppins text-[14px]">
											Contact live chat
										</span>
									</button>
								</div>
								<div className="mt-12 flex flex-col items-center">
									<EmailIcon className="text-grey" />
									<span className="mt-2 font-poppins text-[12px] text-grey">
										Send us an email
									</span>
									<span className="font-poppins font-bold text-blue">
										contact@diaspay.finance
									</span>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>

			<AppBar />
		</>
	)
}

export default Help
