import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { TransactionData } from '../../../types/transaction'
import { checkIfNumber, formatCurrency } from '../../../utils/utils'
import { currencySymbols } from '../../../enums/wallet'
import Button from '../../../components/Inputs/Button/Button'
import { ReactComponent as MasterCard } from '../../../assets/mastercard.svg'
import { ReactComponent as Visa } from '../../../assets/visa.svg'
import { ReactComponent as AmericanExpress } from '../../../assets/amex.svg'
import clsx from 'clsx'
import useAddMoney from '../../../hooks/mutations/useAddMoney'
import { useNavigate } from '@tanstack/react-location'
import useWalletsQuery from '../../../hooks/queries/useWalletsQuery'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
	transactionData: TransactionData
}

interface CardDetails {
	cardNo: string
	cardCvv: number
	cardExpiryDate: string
}
const Card = ({ transactionData }: Props) => {
	const navigate = useNavigate()

	const schema = yup.object().shape({
		cardNo: yup.string().required(),
		cardCvv: yup.number().required(),
		cardExpiryDate: yup
			.string()
			.matches(/^(0[1-9]|1[0-2]) \/ \d{2}$/, 'Enter a valid MM / YY format')
			.test('is-not-expired', 'Card is expired', (value) => {
				if (value) {
					const currentDate = new Date()
					const [mm, yy] = value.split(' / ')
					const cardExpiry = new Date(
						2000 + parseInt(yy, 10),
						parseInt(mm, 10) - 1,
						1
					)

					return cardExpiry > currentDate
				}
				return false
			})
			.required(),
	})
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm<CardDetails>({
		resolver: yupResolver(schema),
	})

	const { mutateAsync: addMoney, isLoading: isAddingMoney } = useAddMoney()
	const {
		isLoading: fetchingWallets,
		refetch: refetchWallets,
		error: walletError,
	} = useWalletsQuery()

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let inputValue: string = e.target.value
		inputValue = inputValue.replace(/\D/g, '')
		if (inputValue.length > 2) {
			inputValue = inputValue.slice(0, 2) + ' / ' + inputValue.slice(2, 4)
		}
		setValue('cardExpiryDate', inputValue, { shouldValidate: true })
	}

	const handleCardNoInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		let cardNumber: string = e.target.value
		cardNumber = cardNumber.replace(/\D/g, '')

		if (cardNumber.length > 4) {
			cardNumber = cardNumber.match(/.{1,4}/g)!.join(' ')
		}

		if (cardNumber.length <= 19) {
			setValue('cardNo', cardNumber, { shouldValidate: true })
		}
	}

	const handlePay = async (data: CardDetails) => {
		addMoney({
			...data,
			amount: transactionData.amount,
			currencyCode: transactionData.currencyCode,
		})
			.then(() => {
				refetchWallets()
				navigate({ to: '/user/home' })
			})
			.catch((error) => console.log(error))
	}

	return (
		<AnimatePresence>
			<motion.div
				className="flex flex-col gap-6 px-4 md:w-1/2 md:max-w-[500px]"
				initial={{ x: -100, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: -100, opacity: 0 }}
			>
				<div className="text-title-2 font-bold text-grey/50">
					{currencySymbols[transactionData.currencyCode]}{' '}
					{formatCurrency(transactionData.amount)}
				</div>
				<div className="rounded-md bg-white p-4 shadow-lg">
					<fieldset>
						<legend className="text-sm text-gray-900 flex w-full items-center justify-start font-medium leading-6">
							<MasterCard className="h-10 w-10" />
							<Visa className="h-10 w-10" />
							<AmericanExpress className="h-8 w-8" />
						</legend>
						<div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
							<div>
								<label htmlFor="card-number" className="sr-only">
									Card number
								</label>
								<input
									type="text"
									id="card-number"
									className={clsx(
										'bg-transparent placeholder:text-gray-400 text-gray-600 sm:text-sm relative block w-full rounded-none rounded-t-md border-0 px-4 py-1.5 font-poppins ring-1 ring-inset ring-dark-grey/50 placeholder:font-poppins focus:z-10 focus:outline-none sm:leading-6',
										{
											'ring-error': errors.cardNo,
										}
									)}
									placeholder="Card number"
									onKeyDown={(event) => checkIfNumber(event)}
									{...register('cardNo')}
									maxLength={19}
									onChange={handleCardNoInput}
								/>
							</div>
							<div className="flex -space-x-px">
								<div className="w-1/2 min-w-0 flex-1">
									<label htmlFor="card-expiration-date" className="sr-only">
										Expiration date
									</label>
									<input
										type="text"
										id="card-expiration-date"
										className={clsx(
											'bg-transparent text-gray-600 placeholder:text-gray-400 sm:text-sm relative block w-full rounded-none rounded-bl-md border-0 px-4 py-1.5 font-poppins ring-1 ring-inset ring-dark-grey/50 placeholder:font-poppins focus:z-10 focus:outline-none sm:leading-6',
											{
												'ring-error': errors.cardExpiryDate,
											}
										)}
										placeholder="MM / YY"
										maxLength={7}
										onKeyDown={(event) => checkIfNumber(event)}
										{...register('cardExpiryDate')}
										onChange={handleInput}
									/>
								</div>
								<div className="min-w-0 flex-1">
									<label htmlFor="card-cvv" className="sr-only">
										CVV
									</label>
									<input
										className={clsx(
											'bg-transparent text-gray-600 placeholder:text-gray-400 sm:text-sm relative block w-full rounded-none rounded-br-md border-0 px-4 py-1.5 font-poppins ring-1 ring-inset ring-dark-grey/50 placeholder:font-poppins placeholder:font-poppins focus:z-10 focus:outline-none sm:leading-6',
											{
												'ring-error': errors.cardCvv,
											}
										)}
										type="text"
										id="card-cvv"
										placeholder="CVV"
										maxLength={3}
										onKeyDown={(event) => checkIfNumber(event)}
										{...register('cardCvv')}
									/>
								</div>
							</div>
						</div>
					</fieldset>
					<Button
						text="Pay"
						fullWidth
						variant="primary"
						className="mt-6"
						onClick={handleSubmit(handlePay)}
						isLoading={isAddingMoney || fetchingWallets}
					/>
				</div>
			</motion.div>
		</AnimatePresence>
	)
}

export default Card
