import { Outlet, ReactLocation, Router } from '@tanstack/react-location'
import type { ReactElement } from 'react'
import FullPageSpinner from './components/Feedback/Spinner/FullPageSpinner'
import useGeolocation from './hooks/useGeolocation'
import NotAvailableInCountry from './pages/404/NotAvailableInCountry'
import routes from './routes'
import { ALLOWED_COUNTRIES } from './utils/DefaultSettings'
import MswToggler from './mocks/MswToggler'

const reactLocation = new ReactLocation()

const App = (): ReactElement => {
	const geo = useGeolocation()

	if (geo.isLoading) {
		return <FullPageSpinner />
	}

	if (
		geo.countryName &&
		!ALLOWED_COUNTRIES.includes(geo?.countryName?.toUpperCase())
	) {
		return <NotAvailableInCountry />
	}

	return (
		<>
			<Router location={reactLocation} routes={routes}>
				<Outlet />
			</Router>
			<MswToggler />
		</>
	)
}

export default App
