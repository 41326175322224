import { Disclosure as HeadlessDisclosure, Transition } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
	title: string
	children: ReactNode | ReactNode[]
}
function Disclosure({ title, children }: Props) {
	return (
		<HeadlessDisclosure>
			{({ open }) => (
				<>
					<HeadlessDisclosure.Button
						className={clsx(
							'flex h-16 w-full items-center justify-between rounded-md py-2 px-4 md:w-1/2',
							open ? 'bg-blue text-white' : 'bg-white'
						)}
					>
						{title}
						<ChevronRightIcon
							className={clsx('h-6 w-6', open ? 'rotate-90 transform' : '')}
						/>
					</HeadlessDisclosure.Button>
					<Transition
						show={open}
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0"
					>
						<HeadlessDisclosure.Panel className="text-gray-500 mt-1 min-h-[100px] rounded-md bg-white p-4 md:w-1/2">
							{children}
						</HeadlessDisclosure.Panel>
					</Transition>
				</>
			)}
		</HeadlessDisclosure>
	)
}

export default Disclosure
