import clsx from 'clsx'
import type { ReactElement, ReactNode } from 'react'

interface PageContainerProperties {
	children: ReactNode | ReactNode[]
	maxWidth?: 'lg' | 'md' | 'sm' | 'xl'
	fillHeight?: boolean
	className?: string
}

const PageContainer = ({
	children,
	maxWidth = 'sm',
	fillHeight = true,
	className,
}: PageContainerProperties): ReactElement => (
	<div
		className={clsx('mx-auto flex w-full max-w-8xl  flex-col px-4 lg:px-8', {
			'h-full': fillHeight,
		})}
	>
		<div
			className={clsx(
				'mx-auto flex w-full flex-1 pb-6',
				{
					'max-w-sm': maxWidth === 'sm',
					'max-w-xl': maxWidth === 'md',
					'max-w-3xl': maxWidth === 'lg',
					'max-w-8xl': maxWidth === 'xl',
				},
				className
			)}
		>
			{children}
		</div>
	</div>
)

interface BannerProperties {
	children: ReactNode | ReactNode[]
	until?: 'lg' | 'md' | 'sm' | 'xl'
	maxWidth?: 'lg' | 'md' | 'sm' | 'xl'
	className?: string
}

const Banner = ({
	children,
	until,
	maxWidth,
	className,
}: BannerProperties): ReactElement => (
	<div
		className={clsx(
			'relative left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] w-screen',
			{ 'sm:left-0 sm:right-0 sm:mx-0 sm:w-auto': until === 'sm' },
			{ 'md:left-0 md:right-0 md:mx-0 md:w-auto': until === 'md' },
			{ 'lg:left-0 lg:right-0 lg:mx-0 lg:w-auto': until === 'lg' },
			{ 'xl:left-0 xl:right-0 xl:mx-0 xl:w-auto': until === 'xl' },
			className
		)}
	>
		{maxWidth ? (
			<PageContainer
				maxWidth={maxWidth}
				className="flex-col"
				fillHeight={false}
			>
				{children}
			</PageContainer>
		) : (
			children
		)}
	</div>
)

PageContainer.Banner = Banner

export default PageContainer
