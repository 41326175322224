import Info from '../../../../components/Feedback/Info/Info'
import SimpleHeader from '../../../../components/Globals/SimpleHeader/SimpleHeader'
import PageContainer from '../../../../components/Layout/PageContainer/PageContainer'

const EmailVerificationSent = () => {
	return (
		<>
			<SimpleHeader className="m-0 flex w-full justify-end " />
			<main className="min-h-[calc(100vh-3.5rem)] bg-new-grey font-poppins">
				<PageContainer maxWidth="sm">
					<div className="flex grow flex-col justify-between gap-20 pt-6 pb-40 sm:justify-center sm:gap-5 sm:pt-14">
						<div className="flex grow flex-col gap-5 pb-40 sm:justify-center">
							<h1 className=" text-[1.25rem] font-bold text-grey sm:gap-5 sm:text-[1.5rem]">
								We've received your request
							</h1>
							<Info
								text="If your email is registered with us we will send your a link to verify you."
								type="success"
								className="mt-3 text-[12px]"
							/>
						</div>
					</div>
				</PageContainer>
			</main>
		</>
	)
}

export default EmailVerificationSent
