import { useState } from 'react'
import SimpleHeader from '../../../../components/Globals/SimpleHeader/SimpleHeader'
import TextInputField from '../../../../components/Inputs/TextInput/TextInputField'
import PageContainer from '../../../../components/Layout/PageContainer/PageContainer'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import useApi from '../../../../hooks/useApi'
import EmailVerificationSent from './EmailVerificationSent'
import Button from '../../../../components/Inputs/Button/Button'
import Info from '../../../../components/Feedback/Info/Info'

const ResendVerificationEmail = ({ message }: { message?: string }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [emailSent, setEmailSet] = useState(false)

	const schema = yup.object().shape({
		email: yup.string().email().required(),
	})
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ email: string }>({
		resolver: yupResolver(schema),
	})

	const resetPasswordHandler = ({ email }: { email: string }) => {
		setIsLoading(true)
		const api = useApi()
		api
			.post('/auth/resend-verification-email', { email })
			.then(() => {
				setIsLoading(false)
				setEmailSet(true)
			})
			.catch(() => {
				setEmailSet(true)
				setIsLoading(false)
			})
	}

	if (emailSent) return <EmailVerificationSent />
	return (
		<>
			<SimpleHeader className="m-0 flex w-full justify-end ">
				<SimpleHeader.ExitLink aria-label="exit login" to={'/'} />
			</SimpleHeader>
			<main className=" h-[calc(100vh-3.5rem)] bg-new-grey font-poppins">
				<PageContainer maxWidth="sm">
					<div className="flex grow flex-col justify-between gap-20 pt-16 pb-40 sm:justify-center sm:gap-5">
						<div className="flex grow flex-col gap-5 pt-16 pb-40 sm:justify-center">
							<h1 className=" text-[1.25rem] font-bold text-grey sm:gap-5 sm:text-[1.5rem]">
								Resend Verification Email
							</h1>
							{message && (
								<Info
									text={message}
									type="error"
									className="mt-3 text-[12px]"
								/>
							)}
							<div className="mb-2">
								<TextInputField
									{...register('email')}
									type=""
									id="number"
									placeholder="Email"
									className="w-full"
									{...(errors.email && { error: true })}
								/>
							</div>

							<Button
								text="Send"
								variant="primary"
								fullWidth
								onClick={handleSubmit(resetPasswordHandler)}
								isLoading={isLoading}
								isDisabled={isLoading}
							/>
						</div>
					</div>
				</PageContainer>
			</main>
		</>
	)
}

export default ResendVerificationEmail
