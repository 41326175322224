import { CircleNotch } from '@phosphor-icons/react'
import clsx from 'clsx'
import Logo from '../../Logo/Logo'

const FullPageSpinner = ({
	withLogo = true,
	withSpinner = true,
	isOpaque = false,
}: {
	withLogo?: boolean
	withSpinner?: boolean
	isOpaque?: boolean
}) => {
	return (
		<>
			{isOpaque && (
				<div
					className={clsx(
						'absolute top-0 left-0 z-[180] min-h-[calc(100vh)] w-[100%] bg-[#111111] font-poppins',
						{ 'bg-opacity-50': isOpaque }
					)}
				/>
			)}
			<div
				className={clsx(
					'absolute top-0 left-0 z-[200] min-h-[calc(100vh)] w-[100%] bg-light-lemon font-poppins'
				)}
			>
				<div className="flex h-[100vh] flex-col items-center justify-center gap-2">
					{withSpinner && (
						<CircleNotch
							data-testid="spinner"
							size={withLogo ? 32 : 64}
							className={clsx(' animate-spin text-blue')}
						/>
					)}
					{withLogo && <Logo />}
				</div>
			</div>
		</>
	)
}

export default FullPageSpinner
