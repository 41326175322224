import { Link } from '@tanstack/react-location'
import type { ReactElement } from 'react'
import SimpleHeader from '../../components/Globals/SimpleHeader/SimpleHeader'
import Logo from '../../components/Logo/Logo'
import LinkButton from '../../components/Navigation/LinkButton/LinkButton'
import { Divider } from '@mui/material'

const Intro = (): ReactElement => {
	return (
		<>
			<SimpleHeader className="flex justify-end shadow-md">
				<Link
					to="/auth/sign-in"
					className="rounded-lg p-2 hover:bg-light-grey hover:underline sm:text-title-3"
				>
					Log in
				</Link>
			</SimpleHeader>
			<div className="flex w-full">
				<div className="hidden flex-1 place-items-center bg-new-grey md:grid"></div>
				<div className="flex h-[calc(100vh-3.5rem)] w-full flex-col  items-center justify-center bg-new-grey px-3 pb-40 font-poppins md:flex-1">
					<div className="mt-[12rem] mb-[4rem] flex max-w-md flex-1 grow flex-col text-center sm:mb-10 sm:mt-10 sm:block sm:max-w-lg sm:flex-none sm:grow-0">
						<Logo className="mb-[3.5rem] text-center text-title-large font-extrabold" />
						<span className=" font-poppins text-[14px] text-grey">
							Diaspay helps you get foreign currencies faster at affordable
							local rates.
						</span>
					</div>
					<span className=" mb-10 flex w-[96%] max-w-sm flex-1 flex-col gap-4 sm:flex-none">
						<LinkButton
							text="Exchange market"
							variant="primary"
							fullWidth
							to="/market"
						/>
						<LinkButton
							text="Create a diaspay account"
							variant="secondary"
							to="/auth/create-account"
							fullWidth
						/>
					</span>
					<Divider className=" w-full max-w-sm text-grey">OR</Divider>

					<div className="mt-16 flex w-full max-w-sm">
						<LinkButton
							text="Login"
							variant="primary"
							fullWidth
							to="/auth/sign-in"
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Intro
