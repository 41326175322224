export const mockWallets = {
	gEPOU6l6: [
		{
			id: 1,
			balance: 9500.0,
			currency: {
				name: 'Pound',
				code: 'GBP',
			},
			last_updated_at: '2023-09-19T07:50:30.010433',
		},
		{
			id: 2,
			balance: 100000.0,
			currency: {
				name: 'Naira',
				code: 'NGN',
			},
			last_updated_at: '2023-09-19T07:50:29.970496',
		},
	],
	rd4Lc4uB: [
		{
			id: 3,
			balance: 1200.0,
			currency: {
				name: 'Pound',
				code: 'GBP',
			},
			last_updated_at: '2023-09-19T07:50:30.010433',
		},
		{
			id: 4,
			balance: 2000000.0,
			currency: {
				name: 'Naira',
				code: 'NGN',
			},
			last_updated_at: '2023-09-19T07:50:29.970496',
		},
	],
	zJXXqOol: [
		{
			id: 5,
			balance: 7500.0,
			currency: {
				name: 'Pound',
				code: 'GBP',
			},
			last_updated_at: '2023-09-19T07:50:30.010433',
		},
		{
			id: 6,
			balance: 4000.0,
			currency: {
				name: 'Naira',
				code: 'NGN',
			},
			last_updated_at: '2023-09-19T07:50:29.970496',
		},
	],
	uil212ZB: [
		{
			id: 7,
			balance: 3500.0,
			currency: {
				name: 'Pound',
				code: 'GBP',
			},
			last_updated_at: '2023-09-19T07:50:30.010433',
		},
		{
			id: 8,
			balance: 20000.0,
			currency: {
				name: 'Naira',
				code: 'NGN',
			},
			last_updated_at: '2023-09-19T07:50:29.970496',
		},
	],
}
