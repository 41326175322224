import { ConfigProvider, Tabs } from 'antd'
import Buy from './Buy'
import Sell from './Sell'

const tabs = ['Buy', 'Sell']
function MarketTab() {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#425C81',
					fontWeightStrong: 800,
					colorPrimaryActive: '#425C81',
				},
			}}
		>
			<Tabs
				defaultActiveKey="1"
				size="middle"
				centered
				tabBarStyle={{
					fontFamily: 'Poppins',
					fontWeight: 700,
					textTransform: 'uppercase',
					color: 'grey',
				}}
				items={tabs.map((tab, i) => {
					const id = String(i + 1)
					return {
						label: tab,
						key: id,
						children: <>{tab === 'Buy' ? <Buy /> : <Sell />}</>,
					}
				})}
			/>
		</ConfigProvider>
	)
}

export default MarketTab
