import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import { AuthProvider } from './contexts/AuthProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import { MarketProvider } from './contexts/MarketProvider'
import { ConfigProvider } from 'antd'

const MAX_RETRIES = 0

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Number.POSITIVE_INFINITY,
			retry: MAX_RETRIES,
		},
	},
})

if (import.meta.env.DEV) {
	//@ts-ignore
	import('./mocks/browser').catch(() => {
		console.error('Failed to start MSW')
	})
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<MarketProvider>
					<ConfigProvider>
						<App />
						<ToastContainer
							position="bottom-left"
							autoClose={3200}
							hideProgressBar
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</ConfigProvider>
				</MarketProvider>
			</AuthProvider>
		</QueryClientProvider>
	</React.StrictMode>
)
