import { faker } from '@faker-js/faker'
import { FAKER_SEED } from '../../utils/constants'
import { Status } from '../../enums/status'

faker.seed(FAKER_SEED)

const generateListing = () => {
	return {
		id: faker.string.uuid(),
		seller: faker.string.alphanumeric(8),
		amount: Number(faker.finance.amount(50, 4000)),
		rate: Number(faker.finance.amount(1100, 1250)),
		fee: 5,
		source: {
			currency: 'GBP',
			amount: Number(faker.finance.amount(50, 10000)),
		},
		destination: {
			currency: 'NGN',
			amount: Number(faker.finance.amount(50, 10000)),
		},
		status: 'open',
		allow_negotiation: true,
		allow_partial_swap: true,
		created_date: faker.date.recent().toISOString(),
	}
}

const generateListings = () => Array.from({ length: 5 }, generateListing)

export const listings = generateListings()
