import { useState } from 'react'
import clsx from 'clsx'
import { Switch } from '@headlessui/react'
// @ts-ignore
import worker from './browser'

const MswToggler = () => {
	const [mockingEnabled, setMockingEnabled] = useState(
		localStorage.getItem('mswEnabled') === 'true'
	)

	const toggleMocking = () => {
		if (mockingEnabled) {
			setMockingEnabled(false)
			localStorage.setItem('mswEnabled', 'false')
			worker.stop()
		} else {
			setMockingEnabled(true)
			localStorage.setItem('mswEnabled', 'true')
			worker.start({ onUnhandledRequest: 'bypass', waitUntilReady: true })
		}
	}

	return (
		<div className="fixed bottom-4 right-4   hidden items-center gap-2">
			<span className="font-poppins text-grey">
				{mockingEnabled ? 'Mocking ✅' : 'Mocking ❌'}
			</span>
			<Switch
				checked={mockingEnabled}
				onChange={toggleMocking}
				className="focus:ring-red-600 group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2"
			>
				<span className="sr-only">Mocking</span>
				<span
					aria-hidden="true"
					className="pointer-events-none absolute h-full w-full rounded-md "
				/>
				<span
					aria-hidden="true"
					className={clsx(
						mockingEnabled ? 'bg-blue' : 'bg-lemon',
						'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
					)}
				/>
				<span
					aria-hidden="true"
					className={clsx(
						mockingEnabled ? 'translate-x-5 bg-blue' : 'translate-x-0 bg-white',
						'border-gray-200 pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border  shadow ring-0 transition-transform duration-200 ease-in-out'
					)}
				/>
			</Switch>
		</div>
	)
}

export default MswToggler
