import { setupWorker } from 'msw'
import { handlers } from './handlers'

const worker = setupWorker(...handlers)

if (localStorage.getItem('mswEnabled') === 'true')
	worker.start({
		onUnhandledRequest: 'bypass',
	})

export default worker
