import { IconButton } from '@mui/joy'
import { Listing } from '../../api/models/market/Listings'
import { formatCurrency } from '../../utils/utils'
import { format } from 'date-fns'
import { currencySymbols } from '../../enums/wallet'

type Props = {
	listing: Listing
	setSelectedListing: (listing: Listing) => void
	setOpenDrawer: (val: boolean) => void
}

function ListingItem({ listing, setOpenDrawer, setSelectedListing }: Props) {
	return (
		<div
			className="border-1 relative my-2 flex h-16 cursor-pointer items-center rounded-lg border-[#f2f6fc] bg-white px-2 shadow-sm  hover:bg-input-grey hover:bg-opacity-75 hover:shadow-sm"
			key={listing.id}
			onClick={() => {
				setSelectedListing(listing)
				setOpenDrawer(true)
			}}
		>
			<div className="mr-auto flex items-center gap-2">
				<div className="">
					<IconButton
						size="lg"
						variant="plain"
						sx={{
							borderRadius: '50%',
							backgroundColor: '#F2F2F7',
							color: '#425C81',
							fontFamily: 'Poppins',
							fontWeight: 700,
						}}
					>
						{currencySymbols[listing.source.currency]}
					</IconButton>
				</div>

				<div className="ml-1 flex flex-col md:ml-4">
					<span className="font-poppins font-medium">
						{listing.source.currency}
					</span>
					<span className="font-poppins text-[10px] text-grey/60">
						{listing.created_date &&
							format(new Date(listing.created_date), 'eee dd MMM yy hh:mm a')}
					</span>
				</div>
			</div>
			<div className="mr-2">
				<div className="my-1 font-poppins font-semibold text-grey">{`${
					currencySymbols[listing.source.currency]
				} ${formatCurrency(listing.amount)}`}</div>
				<span className="flex flex-col text-[12px]">
					<span className="font-poppins text-[10px]">Exchange rate</span>
					<span className="text-[10px]">{`${
						currencySymbols[listing.destination.currency]
					} ${formatCurrency(listing.rate)}`}</span>
				</span>
			</div>
		</div>
	)
}

export default ListingItem
