import { Select, Checkbox, ConfigProvider } from 'antd'
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft'

import Spinner from '../Feedback/Spinner/Spinner'
import { useMarket } from '../../contexts/MarketProvider'

function CurrencySortFilter() {
	const {
		currencies,
		filters,
		setFilters,
		setSortListType,
		isLoading,
		listings,
	} = useMarket()

	const options = currencies.map((currency) => ({
		label: currency?.name,
		value: currency?.code,
	}))

	const onChange = (value: Record<string, boolean>) => {
		setFilters({ ...filters, ...value })
	}

	if (isLoading)
		return (
			<div className="flex min-h-[100px] ">
				<Spinner className="text-blue" />
			</div>
		)

	return (
		<>
			<div className="mb-6 flex w-full flex-col gap-2 rounded-md border-t-4 border-t-blue bg-white p-4 shadow-md">
				<div className="text-[12px]">Sorts and filters</div>
				<div className="flex w-full flex-col items-center gap-1 md:flex-row md:items-center md:justify-between">
					<Select
						style={{ width: '100%' }}
						defaultValue={'Currency'}
						onChange={(e) => {
							setFilters({
								...filters,
								sourceCurrency: e,
							})
						}}
						options={options}
					/>
					<SwitchLeftIcon className="rotate-90 text-blue md:rotate-0" />
					<Select
						style={{ width: '100%' }}
						defaultValue={'Currency'}
						onChange={(e) => {
							setFilters({
								...filters,
								destinationCurrency: e,
							})
						}}
						options={options}
					/>
				</div>
				<div className="mt-4">
					<Select
						style={{ width: '100%' }}
						defaultValue={'Sort by exchange rate'}
						onChange={(e) => {
							setSortListType(e)
						}}
						options={[
							{ label: 'Ascending', value: 'ASC' },
							{ label: 'Descending', value: 'DESC' },
						]}
					/>
				</div>
				<div className="mt-4">
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: '#425C81',
								fontWeightStrong: 800,
								colorPrimaryActive: '#425C81',
							},
						}}
					>
						<Checkbox
							onChange={(e) => onChange({ isNegotiable: e.target.checked })}
							style={{ fontFamily: 'Poppins', fontSize: 12 }}
						>
							Negotiable
						</Checkbox>
						<Checkbox
							onChange={(e) => onChange({ isPartSale: e.target.checked })}
						>
							Part sale
						</Checkbox>
					</ConfigProvider>
				</div>
			</div>
		</>
	)
}

export default CurrencySortFilter
