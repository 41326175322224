import { useEffect, useState } from 'react'
import Dialog from '../../components/Feedback/Dialog/Dialog'
import { useAuth } from '../../contexts/AuthProvider'
import Header from '../../components/Globals/Header/Header'
import FullPageSpinner from '../../components/Feedback/Spinner/FullPageSpinner'
import Container from '../../components/Globals/Container/Container'
import AppBar from '../../components/Globals/AppBar/AppBar'
import useGreeting from '../../hooks/useGreeting'
import {
	capitalizeFirstLetter,
	formatCurrency,
	getCurrencySymbol,
} from '../../utils/utils'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import AddIcon from '@mui/icons-material/Add'
import SellIcon from '@mui/icons-material/Sell'

import WalletPanel from '../../components/WalletPanel/WalletPanel'
import { Wallet } from '../../api/models/market/Wallets'
import useWalletsQuery from '../../hooks/queries/useWalletsQuery'
import WalletSelection from '../../components/WalletPanel/WalletSelection'
import Services from '../../components/Services/Services'
import MarketTab from '../../components/MarketTab/MarketTab'
import CurrencySortFilter from '../../components/CurrencySortFilter/CurrencySortFilter'
import { useMarket } from '../../contexts/MarketProvider'
import ViewListing from './ViewListing'
import { Fab, styled } from '@mui/material'
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft'
import { Input, Select } from 'antd'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import CustomSwitch from '../../components/Inputs/Switch/CustomSwitch'
import Button from '../../components/Inputs/Button/Button'
import { marketApi } from '../../api'
import { toast } from 'react-toastify'

const CustomFAB = styled(Fab)({
	'&:hover': {
		backgroundColor: '#425C81',
	},
})

interface AddInputs {
	amount: number
	rate: number
	sourceCurrency: string
	destinationCurrency: string
}

const Market = () => {
	const { user, logout, initializingAuth, isAuthenticated } = useAuth()
	const { data: queryData, isLoading: fetchingWallets } = useWalletsQuery()
	const { openDrawer, setOpenDrawer, currencies, loadListings } = useMarket()

	const options = currencies?.map((currency) => ({
		label: currency?.name,
		value: currency?.code,
	}))

	const accounts = queryData?.data.data || []
	const [selectedAccount, setSelectedAccount] = useState<Wallet>(accounts[0])

	const [openAddListingDialog, setOpenAddListingDialog] = useState(false)

	const [openAccountsDialog, setOpenAccountsDialog] = useState(false)

	const [partSell, setPartSell] = useState<boolean>(false)
	const [isNegotiable, setIsNegotiable] = useState<boolean>(false)
	const [isAddLoading, setIsAddLoading] = useState(false)
	const [calculatedAmount, setCalculatedAmount] = useState('0.00')

	useEffect(() => {
		if (!localStorage.getItem('selectedAccountCurrencyCode')) {
			localStorage.setItem(
				'selectedAccountCurrencyCode',
				selectedAccount?.currency?.code
			)
		}
	}, [selectedAccount])

	useEffect(() => {
		const [getSelectedAccount] = accounts.filter(
			(account) =>
				account.currency.code ===
				localStorage.getItem('selectedAccountCurrencyCode')
		)
		setSelectedAccount(getSelectedAccount)
	}, [fetchingWallets, accounts])

	const selectHandler = (selectedAccount: Wallet) => {
		setSelectedAccount(selectedAccount)
		localStorage.setItem(
			'selectedAccountCurrencyCode',
			selectedAccount?.currency?.code
		)
		setOpenAccountsDialog(false)
	}

	const schema = yup.object().shape({
		amount: yup
			.number()
			.typeError('Please enter a valid amount')
			.required('Amount is required')
			.positive('Negative value is not allowed'),
		rate: yup
			.number()
			.typeError('Please enter a valid rate')
			.required('Rate is required')
			.positive('Negative value is not allowed'),
		sourceCurrency: yup
			.string()
			.required('Please select the currency you want to sell'),
		destinationCurrency: yup
			.string()
			.required('Please select the currency you request'),
	})

	const {
		handleSubmit,
		control,
		formState: { errors },
		setError,
		clearErrors,
		getValues,
		reset,
		watch,
	} = useForm<AddInputs>({ resolver: yupResolver(schema) })

	const sourceField = watch('sourceCurrency')
	const destinationField = watch('destinationCurrency')
	const exchangeRateField = watch('rate')
	const amountField = watch('amount')

	useEffect(() => {
		const accountBalance = accounts.find(
			(account) => account.currency.code === sourceField
		)
		if (accountBalance) {
			if (accountBalance?.balance < getValues().amount) {
				setError('amount', {
					type: 'manual',
					message: `Insufficient balance. You have ${getCurrencySymbol(
						sourceField
					)} ${formatCurrency(accountBalance.balance)}`,
				})
			} else if (errors.amount?.message) {
				clearErrors('amount')
			}
		}
	}, [sourceField, watch('amount')])

	useEffect(() => {
		if (exchangeRateField && amountField) {
			const val = exchangeRateField * amountField
			let stringAmount = `${getCurrencySymbol(
				destinationField
			)} ${formatCurrency(val)}`

			setCalculatedAmount(stringAmount)
		}
	}, [exchangeRateField, amountField, sourceField, destinationField])

	const createListingHandler = async (data: AddInputs) => {
		if (data.destinationCurrency === data.sourceCurrency) {
			setError('destinationCurrency', {
				type: 'manual',
				message: 'Destination currency cannot be same the source',
			})
			return
		}

		let check = accounts.find(
			(acc) => acc.currency.code === data.sourceCurrency
		)
		if (check!.balance < data.amount) {
			setError('amount', {
				type: 'manual',
				message: `Insufficient balance. You have ${getCurrencySymbol(
					sourceField
				)} ${formatCurrency(check!.balance)}`,
			})
			return
		}

		setIsAddLoading(true)
		try {
			await marketApi.post('/listings', {
				amount: data.amount,
				rate: data.rate,
				destination_currency: data.destinationCurrency,
				source_currency: data.sourceCurrency,
				allow_negotiation: isNegotiable,
				allow_partial_swap: partSell,
			})
			reset()
			setCalculatedAmount('0.00')
			setIsAddLoading(false)
			setOpenAddListingDialog(false)
			loadListings()
			toast.success('Your listing has been posted!')
		} catch (error) {
			setIsAddLoading(false)
			console.log(error)
		}
	}

	if (initializingAuth || fetchingWallets) return <FullPageSpinner />

	return (
		<>
			<Header />
			<div className="mb-16 mt-16 min-h-screen p-4 font-poppins md:mt-20">
				<Container fullHeight>
					<div className="md:mt-8 md:flex md:justify-between md:gap-4">
						{/**left */}
						<div className="md:fixed md:w-2/6 md:min-w-[340px] md:max-w-[360px]">
							<div className="mb-6">
								<div className="mb-4 font-semibold">
									{isAuthenticated &&
										useGreeting(capitalizeFirstLetter(user?.firstname || ''))}
								</div>
								{isAuthenticated && (
									<WalletPanel
										selectedAccount={selectedAccount!}
										setOpenAccountsDialog={setOpenAccountsDialog}
										isHomePage={false}
									/>
								)}
							</div>
							{/* sort and filters */}
							<CurrencySortFilter />
							{/* {services} */}
							<Services className="hidden md:flex" />
						</div>

						{/**right */}
						<div className="md:ml-[390px] md:flex md:w-4/6 md:flex-col">
							{/* Listing */}

							<div className=" md:mt-2 md:w-full md:max-w-3xl">
								<div className="mb-3 flex items-center justify-between">
									<div className="flex items-center justify-center gap-4">
										<span>Currency Market</span>
										<TrendingUpIcon className="font-poppins font-extrabold text-blue" />
									</div>
								</div>
								<MarketTab />
							</div>
						</div>
					</div>
					{/**pagination */}
					<div className="mt-8 flex justify-center md:justify-end"></div>
				</Container>
			</div>
			{isAuthenticated && (
				<Dialog
					isOpen={openAccountsDialog}
					onClose={() => setOpenAccountsDialog(false)}
					className=" bg-white p-2"
				>
					<Dialog.Title
						as="h3"
						className="text-lg text-gray-600 mb-4 flex font-extrabold leading-6"
					>
						<AccountBalanceWalletIcon className="mr-2 text-blue" />
						Your wallets
					</Dialog.Title>
					<Dialog.Description>
						<WalletSelection
							accounts={accounts}
							selected={selectedAccount}
							setSelected={selectHandler}
						/>
					</Dialog.Description>
				</Dialog>
			)}
			{isAuthenticated && (
				<Dialog
					isOpen={openAddListingDialog}
					onClose={() => setOpenAddListingDialog(false)}
					className="bg-white p-2"
				>
					<Dialog.Title
						as="h3"
						className="text-lg text-gray-600 mb-4 flex font-extrabold leading-6"
					>
						<SellIcon className="mr-2 text-blue" />
						Create a new listing
					</Dialog.Title>
					<Dialog.Description>
						<div className="flex gap-3">
							<Controller
								name="sourceCurrency"
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										onChange={(e) => field.onChange(e)}
										style={{ width: '100%' }}
										defaultValue={'Currency'}
										options={options}
										className={clsx(
											errors.sourceCurrency &&
												'rounded-md border border-error text-error'
										)}
									/>
								)}
							/>

							<SwitchLeftIcon className=" text-blue md:rotate-0" />

							<Controller
								name="destinationCurrency"
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										style={{ width: '100%' }}
										defaultValue={'Currency'}
										options={options}
										className={clsx(
											errors.destinationCurrency &&
												'rounded-md border border-error text-error'
										)}
									/>
								)}
							/>
						</div>
						<div className="mt-3 flex flex-col gap-2">
							<label className="font-poppins text-[14px] text-grey">
								Amount
							</label>
							<Controller
								name="amount"
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										placeholder="Amount"
										type="number"
										className={clsx(errors.amount && 'border border-error')}
										allowClear
									/>
								)}
							/>
							{errors.amount && (
								<span className="font-poppins text-[10px] text-error">
									{errors.amount.message}
								</span>
							)}
						</div>
						<div className="mt-3 flex flex-col gap-2">
							<label className="font-poppins text-[14px] text-grey">
								Exchange rate
							</label>
							<Controller
								name="rate"
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										placeholder="Exchange rate"
										type="number"
										className={clsx(errors.rate && 'border border-error')}
										allowClear
									/>
								)}
							/>
							{errors.rate && (
								<span className="font-poppins text-[10px] text-error">
									{errors.rate.message}
								</span>
							)}
						</div>
						<div className="mt-3 grid gap-2">
							<label className="font-poppins text-[14px] font-extrabold text-blue">
								You get
							</label>

							<Input
								type="text"
								disabled={true}
								value={calculatedAmount}
								className="text-bold"
								style={{
									fontWeight: 'bolder',
									color: '#425C81',
									opacity: 1,
								}}
							/>

							{errors.rate && (
								<span className="font-poppins text-[10px] text-error">
									{errors.rate.message}
								</span>
							)}
						</div>

						<div className="mb-10 mt-4">
							<div className="mb-4 flex items-center justify-between">
								<span className="font-poppins text-[12px] text-grey">
									Willing to negotiate
								</span>
								<CustomSwitch
									checked={isNegotiable}
									onChange={(e) => setIsNegotiable(e.target.checked)}
								/>
							</div>
							<div className="mb-4 flex items-center justify-between">
								<span className="font-poppins text-[12px] text-grey">
									Willing to part-sell
								</span>
								<CustomSwitch
									checked={partSell}
									onChange={(e) => setPartSell(e.target.checked)}
								/>
							</div>
						</div>
						<div>
							<Button
								size="compact"
								text="Create"
								fullWidth
								variant="primary"
								isLoading={isAddLoading}
								onClick={handleSubmit(createListingHandler)}
							/>
						</div>
						<div className="mt-4 font-poppins text-[10px] text-grey">
							By transacting on diaspay, you agree with our{' '}
							<span className="font-bold text-blue">terms of service</span> and{' '}
							<span className="font-bold text-blue">privacy policy</span>
						</div>
					</Dialog.Description>
				</Dialog>
			)}

			<AppBar />
			<ViewListing open={openDrawer} setOpen={setOpenDrawer} />
			{isAuthenticated && !openAddListingDialog && (
				<CustomFAB
					sx={{
						backgroundColor: '#425C81',
						position: 'fixed',
						right: 18,
						bottom: 68,
					}}
					size="small"
					onClick={() => {
						setOpenDrawer(false)
						setOpenAddListingDialog(true)
					}}
				>
					<AddIcon className="text-white" />
				</CustomFAB>
			)}
		</>
	)
}

export default Market
