import Button from '../../components/Inputs/Button/Button'
import { useAuth } from '../../contexts/AuthProvider'
import Header from '../../components/Globals/Header/Header'
import FullPageSpinner from '../../components/Feedback/Spinner/FullPageSpinner'
import Container from '../../components/Globals/Container/Container'
import AppBar from '../../components/Globals/AppBar/AppBar'

const Withdrawal = () => {
	const { user, logout, initializingAuth } = useAuth()

	if (initializingAuth) return <FullPageSpinner />

	return (
		<>
			<Header />
			<div className="mt-18 mb-10 p-4">
				<Container>
					<p></p>
				</Container>
			</div>
			<h1 className="p-4">Withdrawal Page</h1>
			<AppBar />
		</>
	)
}

export default Withdrawal
