import { AxiosError } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import { ApiError } from '../../api/models/ApiError'
import { marketApi } from '../../api'

interface SwapResponse {
	status: string
	message: string
}

interface SwapRequest {
	amount: number
	listingId: string
}

const useSwapCurrency = (): UseMutationResult<
	SwapResponse,
	AxiosError<ApiError>,
	SwapRequest
> => {
	const swapCurrency = async ({ listingId, amount }: SwapRequest) => {
		try {
			const response = await marketApi.post(`/listings/${listingId}/swap`, {
				amount,
			})
			return response.data
		} catch (error) {
			throw error
		}
	}

	return useMutation({
		mutationFn: swapCurrency,
		mutationKey: ['swap-currency'],
	})
}

export default useSwapCurrency
