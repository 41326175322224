import { useEffect, useState } from 'react'

type GeoData = {
	ip: string
	city: string
	countryName: string
	countryCode: string
	latitude?: number
	longitude?: number
	currency?: string
}
const useGeolocation = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [geoData, setGeoData] = useState<GeoData>({
		ip: '',
		city: '',
		countryCode: '',
		countryName: '',
	})

	const getGeoData = async () => {
		setIsLoading(true)
		const response = await fetch('https://ipapi.co/json/')
		const data = await response.json()
		setGeoData({
			ip: data.ip,
			city: data.city,
			countryName: data.country_name,
			countryCode: data.country_code,
		})

		setIsLoading(false)
	}

	useEffect(() => {
		getGeoData()
	}, [])

	return {
		ip: geoData.ip,
		countryName: geoData.countryName,
		city: geoData.city,
		countryCode: geoData.countryCode,
		isLoading,
	}
}

export default useGeolocation
