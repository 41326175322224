import { rest } from 'msw'
import { mockEnpoints } from './endpoints'
import {
	mockAddMoneyController,
	mockGetWalletsController,
} from './controllers/wallet'
import {
	mockAddListingController,
	mockDeleteListingController,
	mockGetListingsController,
	mockSwapListingController,
	mockUpdateListingController,
} from './controllers/listings'
import { mockGetCurrenciesController } from './controllers/currencies'
import {
	mockGetAuthUserController,
	mockGetPostCodeAddressesController,
	mockLoginController,
	mockRegisterController,
	mockSendMobileOtpController,
	mockVerifyMobileController,
} from './controllers/auth'

export const handlers = [
	// Handles POST requests
	rest.post(mockEnpoints.register, mockRegisterController),
	rest.post(mockEnpoints.login, mockLoginController),
	rest.post(mockEnpoints.addListing, mockAddListingController),
	rest.post(mockEnpoints.sendOtp, mockSendMobileOtpController),
	rest.post(mockEnpoints.verifyMobile, mockVerifyMobileController),
	rest.post(
		mockEnpoints.getPostCodeAddress,
		mockGetPostCodeAddressesController
	),
	rest.post(mockEnpoints.swapListing, mockSwapListingController),

	// Handles GET requests
	rest.get(mockEnpoints.getWallets, mockGetWalletsController),
	rest.get(mockEnpoints.getListings, mockGetListingsController),
	rest.get(mockEnpoints.getCurrencies, mockGetCurrenciesController),
	rest.get(mockEnpoints.getAuthUser, mockGetAuthUserController),

	//Handles PUT/PATCH requests
	rest.put(mockEnpoints.updateListing, mockUpdateListingController),
	rest.put(mockEnpoints.fundWallet, mockAddMoneyController),

	//Handles DELETE requests
	rest.delete(mockEnpoints.deleteListing, mockDeleteListingController),
]
