import { WifiSlash } from '@phosphor-icons/react'
import SimpleHeader from '../../components/Globals/SimpleHeader/SimpleHeader'
import PageContainer from '../../components/Layout/PageContainer/PageContainer'

const NotAvailableInCountry = () => {
	return (
		<>
			<SimpleHeader className="m-0 flex w-full justify-end " />
			<main className="min-h-[calc(100vh-3.5rem)] bg-light-lemon font-poppins">
				<PageContainer maxWidth="sm">
					<div className="flex grow flex-col justify-between gap-20 pt-6 pb-40 sm:justify-center sm:gap-5 sm:pt-14">
						<div className="flex grow flex-col gap-5 pb-40 sm:justify-center">
							<div className="flex items-center justify-center">
								<WifiSlash size={65} />
							</div>
							<h1 className=" flex items-center justify-center text-[1.25rem] font-bold text-grey sm:gap-5 sm:text-[1.5rem]">
								Service Unavailable!
							</h1>
							<div className="flex items-center justify-center text-center font-poppins text-[12px] text-grey">
								This app is currently not available in your country.
							</div>
						</div>
					</div>
				</PageContainer>
			</main>
		</>
	)
}

export default NotAvailableInCountry
