import clsx from 'clsx'
import Container from '../Container/Container'
import Logo from '../../Logo/Logo'
import MenuDropDown from '../Menu/Menu'
import { Link } from '@tanstack/react-location'
import { ReactElement } from 'react'
import { useAuth } from '../../../contexts/AuthProvider'

interface NavButtonProperties {
	text: string
	link: string
	className?: string
}

export const activePageAttribute = (): Record<string, string> => ({
	'aria-current': 'page',
})

const NavButton = ({
	text,
	link,
	className,
}: NavButtonProperties): ReactElement => (
	<div className="relative flex h-full w-[100%] items-center">
		<Link to={link} className={clsx('')} getActiveProps={activePageAttribute}>
			{({ isActive }): ReactElement => (
				<span
					className={clsx(
						'box-border flex w-full items-center',
						{
							'text-blue after:absolute after:-bottom-[1px] after:h-[2px] after:w-[100%] after:bg-blue':
								isActive,
						},
						className
					)}
				>
					{text}
				</span>
			)}
		</Link>
	</div>
)

const Header = () => {
	const { isAuthenticated } = useAuth()
	return (
		<nav
			className={clsx(
				'fixed top-0 left-0 z-50 flex h-14 w-full items-center bg-white px-4 font-poppins text-subhead font-semibold shadow sm:h-[4.5rem] sm:text-body'
			)}
		>
			<Container>
				<div className="flex h-full items-center justify-between">
					<div className="flex h-full items-center justify-between gap-12">
						<Logo className="text-sm" />
						<div className="hidden h-full items-center gap-8 md:flex">
							{isAuthenticated && <NavButton text="Home" link="/user/home" />}
							<NavButton text="Market" link="/market" />
						</div>
					</div>
					<div className="flex h-full items-center justify-between gap-3">
						{isAuthenticated && <MenuDropDown />}

						{/* <Button text="Get our mobile app" className="hidden h-2 md:block" /> */}
					</div>
				</div>
			</Container>
		</nav>
	)
}

export default Header
