import { currencyCode } from './../enums/wallet'
import validator from 'validator'

//for firefox and other browser compactibility with number input
export const checkIfNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
	const regex = new RegExp(
		/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
	)
	return !event.key.match(regex) && event.preventDefault()
}

export const checkIfNumberWithDecimal = (
	event: React.KeyboardEvent<HTMLInputElement>
) => {
	const regex = new RegExp(
		/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
	)

	return !event.key.match(regex) && event.preventDefault()
}

export const validatePassword = (password: string) => {
	if (
		!validator.isEmpty(password) &&
		validator.isLength(password, { min: 8 })
	) {
		return true
	}
	return false
}

export const validatePhone = (phone: string) => {
	if (
		!validator.isEmpty(phone) &&
		validator.isLength(phone, { min: 10, max: 15 }) &&
		validator.isNumeric(phone)
	) {
		return true
	}
	return false
}

export const validateEmail = (email: string) => {
	if (!validator.isEmpty(email) && validator.isEmail(email)) {
		return true
	}
	return false
}

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

export const stringToColor = (string: string) => {
	let hash = 0
	let i

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash)
	}

	let color = '#'

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff
		color += `00${value.toString(16)}`.slice(-2)
	}
	/* eslint-enable no-bitwise */

	return color
}

export const stringAvatar = (name: string) => {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
	}
}

export const formatCurrency = (number: number, currencyCode?: string) =>
	new Intl.NumberFormat('en-GB', {
		...(currencyCode ? { style: 'currency', currencyCode } : {}),
		minimumFractionDigits: 2,
	}).format(number)

export const containsSpecialChars = (str: string): boolean => {
	const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
	return specialChars.test(str)
}

export const isUpperCase = (string: string) => /^[A-Z]*$/.test(string)

export const capitalizeFirstLetter = (string: string): string =>
	string.charAt(0).toUpperCase() + string.slice(1)

export const capitalizeFirstLetterOfEachWord = (string: string) => {
	const words = string.toLocaleLowerCase().split(' ')
	return words
		.map((word) => {
			return word[0].toUpperCase() + word.substring(1)
		})
		.join(' ')
}

export const getCurrencySymbol = (currencyCode: string) => {
	switch (currencyCode) {
		case 'NGN':
			return '₦'
		case 'GBP':
			return '£'
		case 'USD':
			return '$'
		default:
			return currencyCode
	}
}

export const isDate18orMoreYearsOld = (
	day: number,
	month: number,
	year: number
): boolean => {
	return new Date(year + 18, month - 1, day) <= new Date()
}
