import clsx from 'clsx'
import { ReactElement } from 'react'

const Container = ({
	className,
	children,
	fullHeight = false,
}: {
	className?: string
	children: ReactElement | ReactElement[]
	fullHeight?: boolean
}) => {
	return (
		<div
			className={clsx(
				'h-full w-full md:m-auto md:max-w-6xl',
				{ 'min-h-screen': fullHeight },
				className
			)}
		>
			{children}
		</div>
	)
}

export default Container
