import { ReactElement, ReactNode } from 'react'
import { ReactComponent as HelpIcon } from '../../../assets/icons/help.svg'
import { ReactComponent as TrendIcon } from '../../../assets/icons/trend.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as PersonIcon } from '../../../assets/icons/person.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg'
import { Link } from '@tanstack/react-location'
import { activePageAttribute } from '../Header/Header'
import clsx from 'clsx'

interface AppBarItemProperties {
	text: string
	icon: ReactNode
	link: string
}

const AppBarItem = ({ text, icon, link }: AppBarItemProperties) => (
	<div className="relative flex h-full w-[100%] items-center justify-center font-poppins text-dark-grey">
		<Link to={link} className={clsx('')} getActiveProps={activePageAttribute}>
			{({ isActive }): ReactElement => (
				<div className="flex w-full flex-col items-center justify-center gap-1 text-center">
					<span
						className={clsx(' items-center', {
							'text-blue before:absolute before:-top-4 before:left-0 before:h-[2px] before:w-[100%] before:rounded-md before:bg-blue ':
								isActive,
						})}
					>
						{icon}
					</span>
					<span
						className={clsx('text-[0.75rem]', {
							'font-bold text-blue': isActive,
						})}
					>
						{text}
					</span>
				</div>
			)}
		</Link>
	</div>
)

const AppBar = () => {
	return (
		<div className="fixed bottom-0 left-0 h-14 w-full border-t border-[#ccc] bg-white px-4 font-poppins shadow md:hidden">
			<div className="flex h-full items-center justify-between gap-4 py-4">
				<AppBarItem
					text="Home"
					icon={<HomeIcon className="h-6 w-6" />}
					link="/user/home"
				/>
				<AppBarItem
					text="Market"
					icon={<TrendIcon className="h-6 w-6" />}
					link="/market"
				/>
				<AppBarItem
					text="Help"
					icon={<HelpIcon className="h-6 w-6 " />}
					link="/help"
				/>
				<AppBarItem
					text="Account"
					icon={<PersonIcon className="h-6 w-6" />}
					link="/user/account"
				/>
				<AppBarItem
					text="Settings"
					icon={<SettingsIcon className="h-6 w-6 " />}
					link="/user/settings"
				/>
			</div>
		</div>
	)
}

export default AppBar
