import { useAuth } from '../../contexts/AuthProvider'
import Spinner from '../Feedback/Spinner/Spinner'
import { useMarket } from '../../contexts/MarketProvider'
import { Space } from 'antd'
import InfoIcon from '@mui/icons-material/Info'
import ListingItem from './Listing'
import { motion } from 'framer-motion'

function Buy() {
	const { isAuthenticated, user, initializingAuth } = useAuth()
	const {
		setOpenDrawer,
		listings: _listings,
		filters,
		setSelectedListing,
		isLoading,
	} = useMarket()

	let listings = _listings.filter(
		(listing) => listing.seller !== user?.customerId
	)

	const container = {
		show: {
			transition: {
				staggerChildren: 0.1,
			},
		},
	}

	const item = {
		hidden: { opacity: 0, y: 20 },
		show: { opacity: 1, y: 0 },
	}

	if (isLoading || initializingAuth)
		return (
			<div className="flex min-h-[100px] ">
				<Spinner className="text-blue" />
			</div>
		)

	if (listings.length === 0) {
		return (
			<Space direction="vertical" style={{ width: '100%' }}>
				<Space direction="vertical" style={{ width: '100%', marginTop: 28 }}>
					<div className="flex h-12 items-center justify-start gap-2 rounded-lg border border-t-blue bg-blue bg-opacity-5 px-4 font-poppins text-[12px] text-blue">
						<InfoIcon />
						<span>
							No Listing available at the moment, please check back later
						</span>
					</div>
				</Space>
			</Space>
		)
	}

	return (
		<motion.div variants={container} initial="hidden" animate="show">
			{listings.map((listing) => (
				<motion.div variants={item} key={listing.id}>
					<ListingItem
						listing={listing}
						setSelectedListing={setSelectedListing}
						setOpenDrawer={setOpenDrawer}
					/>
				</motion.div>
			))}
		</motion.div>
	)
}

export default Buy
