import { customers } from '../data/customer'
import { faker } from '@faker-js/faker'

const mockRequestId = 'oe123342421fg'

export const mockLoginController = (req, res, ctx) => {
	const { email, password } = req.body
	const user = customers.find((customer) => email === customer.email)

	if (!user?.email) {
		return res(
			ctx.delay(250),
			ctx.status(401),
			ctx.json({ message: 'Invalid credentials', error: 'Unauthorized' })
		)
	}

	if (email === user.email && password === 'password123') {
		return res(ctx.delay(250), ctx.status(200), ctx.json({ user }))
	}

	return res(
		ctx.delay(250),
		ctx.status(401),
		ctx.json({ message: 'Invalid credentials', error: 'Unauthorized' })
	)
}

export const mockGetAuthUserController = (req, res, ctx) => {
	const authToken = req.headers.get('X-Auth-Token')
	if (authToken) {
		const user = customers.find((customer) => customer.token === authToken)
		return res(ctx.delay(250), ctx.status(200), ctx.json({ user }))
	}

	return res(
		ctx.delay(250),
		ctx.status(401),
		ctx.json({
			message: 'Token not found, Authorization Denied',
			statusCode: 401,
		})
	)
}

export const mockSendMobileOtpController = (req, res, ctx) => {
	if (req.body.phone) {
		return res(
			ctx.delay(400),
			ctx.status(200),
			ctx.json({ requestId: mockRequestId })
		)
	}
	return res(ctx.status(400))
}

export const mockVerifyMobileController = (req, res, ctx) => {
	const { requestId, otp } = req.body
	if (otp === '1234' || (otp === '2023' && requestId)) {
		return res(
			ctx.delay(500),
			ctx.status(200),
			ctx.json({ result: { status: '0' } })
		)
	}
	return res(ctx.status(400), ctx.json({ message: 'Invalid otp' }))
}

export const mockGetPostCodeAddressesController = (req, res, ctx) => {
	if (req.body.postcode) {
		return res(
			ctx.delay(200),
			ctx.status(200),
			ctx.json([
				{
					summaryline: '2 Albion street, Leicester',
					postcode: req.body.postcode,
				},
				{
					summaryline: '3 Albion street, Leicester',
					postcode: req.body.postcode,
				},
				{
					summaryline: '4 Albion street, Leicester',
					postcode: req.body.postcode,
				},
				{
					summaryline: '5 Albion street, Leicester',
					postcode: req.body.postcode,
				},
				{
					summaryline: '6 Albion street, Leicester',
					postcode: req.body.postcode,
				},
				{
					summaryline: '7 Albion street, Leicester',
					postcode: req.body.postcode,
				},
			])
		)
	}

	return res(ctx.delay(500), ctx.status(201))
}

export const mockRegisterController = (req, res, ctx) => {
	const {
		firstname,
		lastname,
		middlename,
		password,
		phone,
		email,
		dob,
		country,
		address,
		postcode,
	} = req.body
	const isEmailRegistered = customers.some(
		(customer) => customer.email === email
	)
	if (isEmailRegistered) {
		return res(
			ctx.delay(250),
			ctx.status(400),
			ctx.json({
				message: 'Email is already taken',
				error: 'Bad Request',
				statusCode: 400,
			})
		)
	}
	const newCustomer = {
		_id: faker.string.uuid(),
		firstname: firstname,
		lastname: lastname,
		email: email,
		address: address,
		postcode: postcode,
		dob,
		phone,
		isDocumentVerified: false,
		isEmailVerified: true,
		isPhoneVerified: true,
		customerId: faker.string.alphanumeric(8),
		token: faker.string.alphanumeric(32),
	}
	customers.push(newCustomer)
	console.log(customers)
	return res(ctx.delay(500), ctx.status(201))
}
