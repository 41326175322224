import { mockCurrencies } from '../data/currencies'

export const mockGetCurrenciesController = (req, res, ctx) => {
	return res(
		ctx.delay(250),
		ctx.status(200),
		ctx.json({
			status: '00',
			message: 'Ok',
			paginate: {
				total_records: 4,
				total_pages: 1,
				page_size: 20,
				page_number: 1,
			},
			data: mockCurrencies,
		})
	)
}
