import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactElement, useState } from 'react'

interface DialogProperties {
	isOpen: boolean
	onClose?: () => void
	children: ReactElement | ReactElement[]
	maxWidth?: 'lg' | 'md' | 'sm' | 'xl'
	className?: string
}

const Dialog = ({
	isOpen,
	onClose,
	children,
	maxWidth,
	className,
}: DialogProperties) => {
	const [closeAttempted, setCloseAttempted] = useState(false)
	const onCloseWithCloseDisabled = (): void => {
		setCloseAttempted(true)
	}

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<HeadlessDialog
					as="div"
					className="relative z-10"
					onClose={onClose ?? onCloseWithCloseDisabled}
				>
					<div className="bg-black/30 fixed inset-0" aria-hidden="true" />
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className=" fixed inset-0 flex items-center justify-center bg-grey bg-opacity-25 p-4" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto font-poppins">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<HeadlessDialog.Panel
									className={clsx(
										'w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all',
										{
											'bg-white': !className,
										},
										className
									)}
								>
									{children}
								</HeadlessDialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</HeadlessDialog>
			</Transition>
		</>
	)
}

Dialog.Title = HeadlessDialog.Title
Dialog.Description = HeadlessDialog.Description

export default Dialog
