import type { LinkProps } from '@tanstack/react-location'
import { Link } from '@tanstack/react-location'
import type { ReactElement } from 'react'
import { getButtonClasses } from '../../Inputs/Button/Button'

interface LinkButtonProperties extends LinkProps {
	/** The text displayed on the button */
	text?: string
	/** The button variant */
	variant?: 'primary' | 'secondary'
	/** The size of the button */
	size?: 'compact' | 'regular'
	/** Makes the button fill the full width of its parent */
	fullWidth?: boolean
	/** Determines whether the button is disabled */
	isDisabled?: boolean
	/** Allows extra classes to be added to the button, such as margins */
	className?: string
	children?: ReactElement | ReactElement[]
}

const LinkButton = ({
	text,
	isDisabled = false,
	variant = 'primary',
	size = 'regular',
	fullWidth = false,
	className,
	children,
	...restProperties
}: LinkButtonProperties): ReactElement => (
	<Link
		className={getButtonClasses(
			variant,
			size,
			isDisabled,
			fullWidth,
			className
		)}
		disabled={isDisabled}
		{...restProperties}
	>
		<span className={className}>
			{text} {children}
		</span>
	</Link>
)

export default LinkButton
