import { Link, useMatch } from '@tanstack/react-location'
import SimpleHeader from '../../../../components/Globals/SimpleHeader/SimpleHeader'
import PageContainer from '../../../../components/Layout/PageContainer/PageContainer'
import LinkButton from '../../../../components/Navigation/LinkButton/LinkButton'
import { useEffect, useState } from 'react'
import FullPageSpinner from '../../../../components/Feedback/Spinner/FullPageSpinner'
import ResendVerificationEmail from './ResendVerificationEmail'
import Info from '../../../../components/Feedback/Info/Info'
import useApi from '../../../../hooks/useApi'

const EmailVerified = () => {
	const route = useMatch()
	const api = useApi()
	const token = route.params.token
	const userId = route.params.userId
	const [isVerifying, setIsVerifying] = useState(true)
	const [error, setError] = useState({
		isError: false,
		response: '',
	})

	useEffect(() => {
		api
			.post('/auth/verify-email', { token, userId })
			.then((response) => {
				setIsVerifying(false)
				console.log(response.data)
			})
			.catch((error) => {
				setIsVerifying(false)
				setError({
					isError: true,
					response: error.response.data.message,
				})
			})
	}, [])

	if (isVerifying) {
		return <FullPageSpinner isOpaque={false} withLogo withSpinner />
	}

	if (error.isError && !isVerifying) {
		return (
			<>
				{error.response === 'This account is already verified!' ? (
					<>
						<SimpleHeader className="m-0 flex w-full justify-end " />
						<main className="min-h-[calc(100vh-3.5rem)] bg-new-grey font-poppins">
							<PageContainer maxWidth="sm">
								<div className="flex grow flex-col justify-between gap-20 pt-6 pb-40 sm:justify-center sm:gap-5 sm:pt-14">
									<div className="flex grow flex-col gap-5 pb-40 sm:justify-center">
										<Info
											text="Your email is verified!"
											type="success"
											className="mt-3 text-[12px]"
										/>
										<LinkButton to="/auth/sign-in" text="Login" />
									</div>
								</div>
							</PageContainer>
						</main>
					</>
				) : (
					<ResendVerificationEmail message="Link may have expired. Please request a new one" />
				)}
			</>
		)
	} else {
		return (
			<>
				<SimpleHeader className="m-0 flex w-full justify-end " />
				<main className="min-h-[calc(100vh-3.5rem)] bg-light-lemon font-poppins">
					<PageContainer maxWidth="sm">
						<div className="flex grow flex-col justify-between gap-20 pt-6 pb-40 sm:justify-center sm:gap-5 sm:pt-14">
							<div className="flex grow flex-col gap-5 pb-40 sm:justify-center">
								<h1 className=" text-[1.25rem] font-bold text-grey sm:gap-5 sm:text-[1.5rem]">
									Your email is now verified
								</h1>
								<LinkButton to="/auth/sign-in" text="Login" />
							</div>
						</div>
					</PageContainer>
				</main>
			</>
		)
	}
}

export default EmailVerified
