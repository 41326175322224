import { faker } from '@faker-js/faker'
import { FAKER_SEED } from '../../utils/constants'

faker.seed(FAKER_SEED)

export const customers = [
	{
		_id: faker.string.uuid(),
		firstname: faker.person.firstName(),
		lastname: faker.person.lastName(),
		email: 'igbinobaroosaretin@gmail.com',
		address: faker.location.streetAddress(),
		postcode: faker.location.zipCode(),
		dob: faker.date.birthdate().toISOString(),
		phone: faker.phone.number(),
		isDocumentVerified: false,
		isEmailVerified: true,
		isPhoneVerified: true,
		customerId: 'zJXXqOol',
		token: faker.string.alphanumeric(32),
	},
	{
		_id: faker.string.uuid(),
		firstname: faker.person.firstName(),
		lastname: faker.person.lastName(),
		email: 'test@gmail.com',
		address: faker.location.streetAddress(),
		postcode: faker.location.zipCode(),
		dob: faker.date.birthdate().toISOString(),
		phone: faker.phone.number(),
		isDocumentVerified: true,
		isEmailVerified: true,
		isPhoneVerified: true,
		customerId: 'gEPOU6l6',
		token: faker.string.alphanumeric(32),
	},
	{
		_id: faker.string.uuid(),
		firstname: faker.person.firstName(),
		lastname: faker.person.lastName(),
		email: 'test1@mail.com',
		address: faker.location.streetAddress(),
		postcode: faker.location.zipCode(),
		dob: faker.date.birthdate().toISOString(),
		phone: faker.phone.number(),
		isDocumentVerified: true,
		isEmailVerified: true,
		isPhoneVerified: true,
		customerId: 'rd4Lc4uB',
		token: faker.string.alphanumeric(32),
	},
	{
		_id: faker.string.uuid(),
		firstname: faker.person.firstName(),
		lastname: faker.person.lastName(),
		email: 'test2@mail.com',
		address: faker.location.streetAddress(),
		postcode: faker.location.zipCode(),
		dob: faker.date.birthdate().toISOString(),
		phone: faker.phone.number(),
		isDocumentVerified: false,
		isEmailVerified: true,
		isPhoneVerified: true,
		customerId: 'uil212ZB',
		token: faker.string.alphanumeric(32),
	},
]
