import { AxiosError } from 'axios'
import { useMutation, UseMutationResult } from 'react-query'
import { ApiError } from '../../api/models/ApiError'
import useApi from '../useApi'
import { AddMoneyRequest, AddMoneyResponse } from '../../types/transaction'
import { marketApi } from '../../api'

const useAddMoney = (): UseMutationResult<
	AddMoneyResponse,
	AxiosError<ApiError>,
	AddMoneyRequest
> => {
	const api = useApi()
	const addMoney = async (addMoneyData: AddMoneyRequest) => {
		try {
			const response = await marketApi.put('/wallets/add-money', addMoneyData)
			return response.data
		} catch (error) {
			throw error
		}
	}

	return useMutation({
		mutationFn: addMoney,
		mutationKey: ['add-money'],
	})
}

export default useAddMoney
