import api, { marketApi } from '../api';

const useApi = () => {
	return api;
};

const useMarketApi = () => marketApi;

export { useMarketApi };
export default useApi;
