import { Link, Navigate } from '@tanstack/react-location'
import { useForm } from 'react-hook-form'
import Info from '../../../components/Feedback/Info/Info'
import SimpleHeader from '../../../components/Globals/SimpleHeader/SimpleHeader'
import Button from '../../../components/Inputs/Button/Button'
import PasswordInputField from '../../../components/Inputs/PasswordInput/PasswordInput'
import TextInputField from '../../../components/Inputs/TextInput/TextInputField'
import PageContainer from '../../../components/Layout/PageContainer/PageContainer'
import Logo from '../../../components/Logo/Logo'
import LinkButton from '../../../components/Navigation/LinkButton/LinkButton'
import { useAuth } from '../../../contexts/AuthProvider'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef } from 'react'
import ResendVerificationEmail from '../CreateAccount/AccountEmailVerification/ResendVerificationEmail'

type LoginFormValues = {
	email: string
	password: string
}

const loginSchema = yup.object().shape({
	email: yup.string().email().required(),
	password: yup.string().required(),
})

const SignIn = () => {
	const { login, initializingAuth, user, isAuthenticated, error } = useAuth()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginFormValues>({
		resolver: yupResolver(loginSchema),
	})

	useEffect(() => {
		return () => {}
	}, [])

	const loginHandler = (data: LoginFormValues) => {
		login(data)
	}

	if (isAuthenticated) return <Navigate to="/user/home" />
	if (error?.response?.data.message === 'Please verify your account')
		return <ResendVerificationEmail />
	return (
		<>
			<SimpleHeader className="m-0 flex w-full justify-end ">
				<SimpleHeader.ExitLink aria-label="exit login" to={'/'} />
			</SimpleHeader>
			<main className=" h-[calc(100vh-3.5rem)] bg-new-grey font-poppins">
				<PageContainer maxWidth="sm">
					<div className="flex grow flex-col justify-between gap-20 pt-12 pb-40 sm:justify-center sm:gap-5">
						<Logo />
						<div>
							<h2 className="py-4 pb-6 text-grey">Sign In</h2>
							<div className="mb-4">
								<TextInputField
									id="email-input"
									type="email"
									placeholder="Email"
									{...(errors.email && { error: true })}
									{...register('email')}
								/>
							</div>
							<div className="mb-8">
								<PasswordInputField
									id="password-input"
									placeholder="password"
									{...(errors.password && { error: true })}
									{...register('password')}
								/>
							</div>
							<Button
								text="Sign In"
								variant="primary"
								fullWidth
								onClick={handleSubmit(loginHandler)}
								isLoading={initializingAuth}
							/>
							<span className="mt-4 flex justify-end text-[0.75rem]">
								<Link to="/auth/forgot-password">Forgot password?</Link>
							</span>
							{error?.response?.data?.message && (
								<Info type="error" text={error.response.data.message} />
							)}

							{errors.email?.message && (
								<Info type="error" text={errors.email.message} />
							)}
							{errors.password?.message && (
								<Info type="error" text={errors.password.message} />
							)}
						</div>
						<LinkButton
							text="I don't have an account"
							variant="secondary"
							className="sm:mt-6"
							to="/auth/create-account"
						/>
					</div>
				</PageContainer>
			</main>
		</>
	)
}

export default SignIn
