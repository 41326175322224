import SimpleHeader from '../../../../components/Globals/SimpleHeader/SimpleHeader'
import Button from '../../../../components/Inputs/Button/Button'
import PasswordInputField from '../../../../components/Inputs/PasswordInput/PasswordInput'
import PageContainer from '../../../../components/Layout/PageContainer/PageContainer'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import validator from 'validator'
import { useState } from 'react'
import { containsSpecialChars } from '../../../../utils/utils'
import { useMatch } from '@tanstack/react-location'
import useApi from '../../../../hooks/useApi'
import LinkButton from '../../../../components/Navigation/LinkButton/LinkButton'
import Info from '../../../../components/Feedback/Info/Info'

const isUpperCase = (string: string) => /^[A-Z]*$/.test(string)

const ResetPassword = () => {
	const route = useMatch()
	const token = route.params.token
	const userId = route.params.userId

	const [error, setError] = useState({
		isError: false,
		message: '',
	})

	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [isPasswordChanged, setIsPasswordChanged] = useState(false)
	const api = useApi()

	const handleOnChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { value, name } = e.target as HTMLInputElement
		if (name === 'password') setPassword(value)
		if (name === 'confirmpassword') setConfirmPassword(value)
	}

	const isDisabled =
		validator.isLength(password, { min: 8 }) &&
		password.split('').some((p) => isUpperCase(p)) &&
		containsSpecialChars(password) &&
		password === confirmPassword &&
		!validator.isEmpty(password)

	const handleChangePassword = () => {
		setLoading(true)
		api
			.post('/auth/reset-password', { userId, token, newPassword: password })
			.then((response) => {
				setLoading(false)
				setIsPasswordChanged(true)
			})
			.catch((error) => {
				setLoading(false)
				setError({
					isError: true,
					message: error,
				})
			})
	}

	if (error.isError) {
		return (
			<>
				<SimpleHeader className="m-0 flex w-full justify-end " />
				<main className="min-h-[calc(100vh-3.5rem)] bg-new-grey font-poppins">
					<PageContainer maxWidth="sm">
						<div className="flex grow flex-col justify-between gap-20 pt-6 pb-40 sm:justify-center sm:gap-5 sm:pt-14">
							<div className="flex grow flex-col gap-5 pb-40 sm:justify-center">
								<h1 className=" text-[1.25rem] font-bold text-grey sm:gap-5 sm:text-[1.5rem]">
									Request a new link.
								</h1>
								<Info
									text={'It looks like this reset link has expired'}
									type="error"
									className="mt-3 text-[12px]"
								/>
								<LinkButton
									to="/auth/forgot-password"
									text="Request a new link"
								/>
							</div>
						</div>
					</PageContainer>
				</main>
			</>
		)
	}

	if (isPasswordChanged) {
		return (
			<>
				<SimpleHeader className="m-0 flex w-full justify-end " />
				<main className="min-h-[calc(100vh-3.5rem)] bg-light-lemon font-poppins">
					<PageContainer maxWidth="sm">
						<div className="flex grow flex-col justify-between gap-20 pt-6 pb-40 sm:justify-center sm:gap-5 sm:pt-14">
							<div className="flex grow flex-col gap-5 pb-40 sm:justify-center">
								<h1 className=" text-[1.25rem] font-bold text-grey sm:gap-5 sm:text-[1.5rem]">
									Your password change was successful.
								</h1>
								<LinkButton to="/auth/sign-in" text="Login" />
							</div>
						</div>
					</PageContainer>
				</main>
			</>
		)
	}

	return (
		<>
			<SimpleHeader className="m-0 flex w-full justify-end ">
				<SimpleHeader.ExitLink aria-label="exit login" to={'/'} />
			</SimpleHeader>
			<main className=" h-[calc(100vh-3.5rem)] bg-light-lemon font-poppins">
				<PageContainer maxWidth="sm">
					<div className="flex grow flex-col justify-between gap-20 pt-16 pb-40 sm:justify-center sm:gap-5">
						<div className="flex grow flex-col gap-5 pt-16 pb-40 sm:justify-center">
							<h1 className=" text-[1.25rem] font-bold text-grey sm:gap-5 sm:text-[1.5rem]">
								Create a new password
							</h1>
							<div className="mb-2">
								<PasswordInputField
									id="password-input"
									name="password"
									placeholder="Password"
									value={password}
									onChange={handleOnChange}
								/>
							</div>
							<div className="mb-4">
								<PasswordInputField
									id="password-input"
									name="confirmpassword"
									placeholder="Confirm password"
									value={confirmPassword}
									onChange={handleOnChange}
								/>
							</div>
							<Button
								text="Continue"
								variant="primary"
								fullWidth
								isDisabled={!isDisabled}
								onClick={handleChangePassword}
								isLoading={loading}
							/>
							<div className="my-6 flex flex-col">
								<div className="flex gap-2 text-[0.75rem] text-grey">
									{validator.isLength(password, { min: 8 }) ? (
										<CheckCircleIcon className="h-4 w-4 text-logo-lemon" />
									) : (
										<XCircleIcon className="h-4 w-4" />
									)}
									<span>Password must be atleast 8 characters long</span>
								</div>
								<div className="flex gap-2 text-[0.75rem] text-grey">
									{containsSpecialChars(password) ? (
										<CheckCircleIcon className="h-4 w-4 text-logo-lemon" />
									) : (
										<XCircleIcon className="h-4 w-4" />
									)}
									<span>Password must contain a special character</span>
								</div>
								<div className="flex gap-2 text-[0.75rem] text-grey">
									{password.split('').some((p) => isUpperCase(p)) ? (
										<CheckCircleIcon className="h-4 w-4 text-logo-lemon" />
									) : (
										<XCircleIcon className="h-4 w-4" />
									)}
									<span>Password must contain atleast one CAPITAL letter</span>
								</div>
								<div className="flex gap-2 text-[0.75rem] text-grey">
									{password === confirmPassword &&
									!validator.isEmpty(password) ? (
										<CheckCircleIcon className="h-4 w-4 text-logo-lemon" />
									) : (
										<XCircleIcon className="h-4 w-4" />
									)}
									<span>Passwords must match</span>
								</div>
							</div>
						</div>
					</div>
				</PageContainer>
			</main>
		</>
	)
}

export default ResetPassword
