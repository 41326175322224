export const mockCurrencies = [
	{
		code: 'USD',
		name: 'American Dollar',
		symbol: '$',
		minor: 'cents',
		units: 2,
		precision: 2,
		iso_num: 840,
	},
	{
		code: 'NGN',
		name: 'Naira',
		symbol: '₦',
		minor: 'kobo',
		units: 2,
		precision: 2,
		iso_num: 566,
	},
	{
		code: 'GBP',
		name: 'Pound',
		symbol: '£',
		minor: 'pence',
		units: 2,
		precision: 2,
		iso_num: 826,
	},
	{
		code: 'EUR',
		name: 'Euro',
		symbol: '€',
		minor: 'cents',
		units: 2,
		precision: 2,
		iso_num: 978,
	},
]
