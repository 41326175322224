import type { Route } from '@tanstack/react-location'
import ProtectedRoute from './components/Routes/ProtectedRoute/ProtectedRoute'
import CreateAccount from './pages/Auth/CreateAccount/CreateAccount'
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword'
import SignIn from './pages/Auth/SignIn/SignIn'
import Home from './pages/Home/Home'
import Intro from './pages/Intro/Intro'
import EmailVerified from './pages/Auth/CreateAccount/AccountEmailVerification/EmailVerified'
import ResetPassword from './pages/Auth/CreateAccount/CreatePassword/ResetPassword'
import Market from './pages/Market/Market'
import Help from './pages/Help/Help'
import Account from './pages/Account/Account'
import Settings from './pages/Settings/Settings'
import AddMoney from './pages/ManagePayment/AddMoney'
import Withdrawal from './pages/ManagePayment/Withdrawal'

const routes: Route[] = [
	{
		path: '/',
		element: <Intro />,
	},

	{
		path: '/auth',
		children: [
			{
				path: '/create-account',
				element: <CreateAccount />,
			},
			{
				path: '/verify-email/:token',
				children: [
					{
						path: ':userId',
						element: <EmailVerified />,
					},
				],
			},
			{ path: '/sign-in', element: <SignIn /> },
			{ path: '/forgot-password', element: <ForgotPassword /> },
			{
				path: '/user/reset-password',
				children: [
					{
						path: ':token/:userId',
						element: <ResetPassword />,
					},
				],
			},
		],
	},
	{
		path: '/user',
		children: [
			{
				path: '/home',
				element: (
					<ProtectedRoute type="authenticated">
						<Home />
					</ProtectedRoute>
				),
			},
			{
				path: '/account',
				element: (
					<ProtectedRoute type="authenticated">
						<Account />
					</ProtectedRoute>
				),
			},
			{
				path: '/settings',
				element: (
					<ProtectedRoute type="authenticated">
						<Settings />
					</ProtectedRoute>
				),
			},
			{
				path: '/add-money',
				element: (
					<ProtectedRoute type="authenticated">
						<AddMoney />
					</ProtectedRoute>
				),
			},

			{
				path: '/withdrawal',
				element: (
					<ProtectedRoute type="authenticated">
						<Withdrawal />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: '/market',
		element: <Market />,
	},
	{
		path: '/help',
		element: <Help />,
	},

	{
		path: '*',
		element: <span>Page not found</span>,
	},
]

export default routes
