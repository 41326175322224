import { CaretDown, CurrencyGbp } from '@phosphor-icons/react'
import { ReactComponent as GBFlag } from '../../assets/gb-flag.svg'
import { ReactComponent as NGFlag } from '../../assets/ng-flag.svg'
import { Wallet } from '../../api/models/market/Wallets'
import { formatCurrency } from '../../utils/utils'
import { CurrencyCode, currencyCode, currencySymbols } from '../../enums/wallet'
import clsx from 'clsx'
import { Link } from '@tanstack/react-location'
import useWalletsQuery from '../../hooks/queries/useWalletsQuery'

interface Props {
	selectedAccount: Wallet
	setOpenAccountsDialog: (val: boolean) => void
	isHomePage?: boolean
}

const renderCountryFlag = (_currencyCode: string) => {
	switch (_currencyCode) {
		case currencyCode.POUNDS:
			return <GBFlag className="h-8 w-8" />

		case currencyCode.NAIRA:
			return <NGFlag className="h-8 w-8" />

		default:
			return <></>
	}
}
function WalletPanel({
	selectedAccount,
	setOpenAccountsDialog,
	isHomePage,
}: Props) {
	const {
		data: queryData,
		isLoading: fetchingWallets,
		refetch: refetchWallets,
		error: walletError,
	} = useWalletsQuery()

	const accounts = queryData?.data.data || []

	const displayedAccount = selectedAccount?.id ? selectedAccount : accounts[0]

	return (
		<div
			className={clsx(
				'flex h-32 w-full flex-col justify-between rounded-md bg-blue p-4',
				!isHomePage && 'hidden md:flex'
			)}
		>
			<div className="flex justify-between gap-1 text-white">
				<div className="flex items-center gap-2">
					<span className="flex items-center">
						<span className=" text-title-1">{`${
							currencySymbols[displayedAccount?.currency?.code as CurrencyCode]
						} ${formatCurrency(displayedAccount?.balance)}`}</span>
					</span>
					<span
						className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-[#3f3d56] bg-[#2e486c] text-center text-white"
						onClick={() => setOpenAccountsDialog(true)}
					>
						<CaretDown size={14} weight="bold" />
					</span>
				</div>
				<div>{renderCountryFlag(displayedAccount?.currency?.code)}</div>
			</div>
			<div className="flex gap-4">
				<Link
					className="cursor-pointer items-center justify-center rounded-sm border border-[#3f3d56] bg-[#2e486c] px-2 py-1 text-center text-[0.75rem] text-white"
					to={'/user/add-money'}
				>
					Add money
				</Link>
				<Link
					className="cursor-pointer items-center justify-center rounded-sm border border-[#3f3d56] bg-[#2e486c] px-2 py-1 text-center text-[0.75rem] text-white"
					to={'/user/withdrawal'}
				>
					Withdraw
				</Link>
			</div>
		</div>
	)
}

export default WalletPanel
